import s from './index.module.scss';
import qrcode from '../../../../images/qrcode.png';
import contactUs from '../../../../images/contact-us.png';
import returnTop from '../../../../images/return_top.png';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import misc from '../../../../services/misc';
const Assistant = ({ returnTopCall, showReturn }) => {
    const { t, i18n } = useTranslation();
    const [show, setShow] = useState(false);
    const is1024 = useSelector(state => state.windowSizeReducer.mobileType === 1024);

    return (
        <div className={`${s.container} ${is1024 ? s.container1024 : ''}`}>
            <div
                className={`${s.qrcode} ${i18n.language === 'en' ? s.qrcodeEn : ''}`}
                style={{ display: show ? undefined : 'none' }}
            >
                <img src={qrcode} alt="" className={s.qrimg} />
                <div className={s.one}>{t('client-code-title')}</div>
                <div className={s.two}>{t('client-code-desc')}</div>
            </div>
            <div className={s.right}>
                <div
                    className={`${s.contact} ${i18n.language === 'en' ? s.contactEn : ''}`}
                    onMouseEnter={() => {
                        misc.eventReport([
                            {
                                event_type: 1,
                                event_id: 30021,
                                ts: +new Date(),
                                sid: '',
                            },
                        ]);
                        setShow(true);
                    }}
                    onMouseLeave={() => setShow(false)}
                >
                    <img src={contactUs} alt="" className={s.contactimg} />
                    {i18n.language === 'zh' && (
                        <div className={s.contacttext}>{t('contact-us')}</div>
                    )}
                </div>
                <div
                    className={s.top}
                    style={{ opacity: showReturn ? '1' : '0' }}
                    onClick={() => {
                        returnTopCall();
                    }}
                >
                    <img src={returnTop} alt="" />
                </div>
            </div>
        </div>
    );
};
export default Assistant;
