import s from './index.module.scss';
import companyIcon from '../../../../images/contact-company.png';
import HomeTitle from '../HomeTitle-mobile';
import { useTranslation } from 'react-i18next';

const Company = () => {
    const { t } = useTranslation();

    return (
        <div className={s.body}>
            <HomeTitle title={t('company-title')}></HomeTitle>
            <div className={s.subtitle}>{t('company-desc')}</div>
            <img src={companyIcon} alt="" className={s.right} />
        </div>
    );
};
export default Company;
