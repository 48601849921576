import styles from './index.module.scss';
import advantageLineColor from '../../../../images/advantage-line-color.png';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const HomeTitle = ({ title, titleColor }) => {
    const { t, i18n } = useTranslation();
    const isEN = i18n.language === 'en'
    const is1024 = useSelector(state => state.windowSizeReducer.mobileType === 1024);
    return (
        <div className={`${styles.body} ${is1024 ? styles.body1024 : ''} ${isEN && styles.bodyEn}`}>
            <div className={styles.imgContent}>
                <img src={advantageLineColor} alt="" className={styles.lkAdvantageLine} />
            </div>
            <div className={styles.title} style={{ color: titleColor }}>
                {title}
            </div>
        </div>
    );
};
export default HomeTitle;
