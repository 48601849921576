import './index.scss';
import qrcodeOfficial from '../../../../images/qr_code_wechat_official.png';
import emailIcon from '../../../../images/email.png';
import qrcode from '../../../../images/qrcode.png';
import logoFotter from '../../../../images/logo_footer_mobile.png';
import logoFotter_en from '../../mobile_images/logo_header_en.png';
import { useTranslation } from 'react-i18next';

const QRCode = props => {
    const { t, i18n } = useTranslation();

    return (
        <div className="lk-mobile-contack-qr-code">
            {/* <img src={props.imgSrc} alt="QR Code" /> */}
            <img src={props.imgUrl} alt="" />
            <div className="lk-mobile-contack-title">{props.title}</div>
            <div
                className={`lk-mobile-contack-subtitle ${
                    i18n.language === 'en' ? 'lk-mobile-contack-subtitle-en' : ''
                }`}
            >
                {props.subtitle}
            </div>
        </div>
    );
};
const Contact = () => {
    const { t, i18n } = useTranslation();
    const isZH = i18n.language === 'zh';

    return (
        <>
            <div className="lk-mobile-contack-info">
                <div className="lk-mobile-contack-info-title">
                    <span className="nav-anchor" id="contact"></span>
                    {t('contact-us-mobie')}
                </div>
                <div className="lk-mobile-contack-qr-codes">
                    <QRCode
                        imgUrl={qrcodeOfficial}
                        title={t('official-code-title')}
                        subtitle={t('official-code-desc')}
                    />
                    <QRCode
                        imgUrl={qrcode}
                        title={t('client-code-title')}
                        subtitle={t('client-code-desc')}
                    />
                </div>
                <div className="lk-mobile-contack-text-info">
                    <div className="lk-mobile-contack-text-info-sub1">{t('address-sg-mobile')}</div>
                    <div className="lk-mobile-contack-text-info-sub2">
                        Centennial Tower #10-01A, 3 Temasek Avenue, Singapore 039190
                    </div>

                    <div className="lk-mobile-contack-text-info-sub1">
                        {t('address-china-mobile')}
                    </div>
                    <div className="lk-mobile-contack-text-info-sub2">{t('address-detail')}</div>
                    <div className="lk-mobile-contack-text-info-sub1">{t('address-hongkong')}</div>
                    <div className="lk-mobile-contack-text-info-sub2">
                        {t('address-hongkong-detail')}
                    </div>
                    {/* <div className="lk-mobile-contack-text-info-sub5">Email:hello@ilookcap.com</div> */}
                    <a
                        className="lk-mobile-contack-text-info-sub5"
                        href="mailto:hello@ilookcap.com?subject=test&subject=&body="
                    >
                        <img src={emailIcon} alt="" className="lk-contact-email"></img>
                        Email:hello@ilookcap.com
                    </a>
                </div>
                <div className="lk-mobile-contack-footer">
                    <img
                        src={isZH ? logoFotter : logoFotter_en}
                        alt=""
                        className="lk-mobile-contack-footer-img"
                    />
                    <div className="lk-mobile-contack-footer-content">
                        <div className="lk-mobile-contack-footer-text">
                            2024 LOOK CAPITAL © Copyright
                        </div>
                        <div className="lk-mobile-contack-footer-text">
                            京 ICP 备 2021012808 号 -1
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Contact;
