import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './CardWithChart.module.scss';
import * as echarts from 'echarts';
import { initOption } from './utils';

// type1
export default ({ data }) => {
    const lineChartContentRef = useRef(null);
    const lineChartRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [hoverData, setHoverData] = useState(null);
    useEffect(() => {
        if (lineChartContentRef.current) {
            lineChartRef.current = echarts.init(lineChartContentRef.current);
        }
    }, [lineChartContentRef.current]);
    useEffect(() => {
        try {
            lineChartRef.current?.setOption(
                initOption(data, selectedIndex, hoverData => {
                    const dataIndex = hoverData[0].dataIndex;
                    const hoverLane = data.data.charts[selectedIndex].y[0];
                    const value = hoverLane?.values[dataIndex];
                    const showData =
                        hoverLane?.top_left_fields?.map(element => {
                            const item = {
                                value: value[element] + hoverLane.suffixes[element],
                                color: null,
                            };
                            if (hoverLane.base_line_value[element]) {
                                if (value[element] > hoverLane.base_line_value[element]) {
                                    item.color = '#ff0000';
                                } else {
                                    item.color = '#4cd964';
                                }
                            }
                            return item;
                        }) || [];
                    setHoverData(showData);
                }),
            );
        } catch (err) {}
    }, [data, lineChartRef.current, selectedIndex]);

    useEffect(() => {
        lineChartRef.current?.on('mouseover', function () {
            lineChartRef.current?.dispatchAction({
                type: 'downplay',
            });
        });
        lineChartRef.current?.on('brush', () => {
            setHoverData(null);
        });
    }, [lineChartRef.current]);
    useEffect(() => {
        const preventDefault = function (e) {
            e.preventDefault();
            e.stopPropagation();
        };
        if (lineChartContentRef.current) {
            lineChartContentRef.current.addEventListener('touchmove', preventDefault);
        }
        return () => {
            if (lineChartContentRef.current) {
                lineChartContentRef.current.addEventListener('touchmove', preventDefault);
            }
        };
    }, []);
    const handleTouchEnd = useCallback(() => {
        if (lineChartRef.current) {
            lineChartRef.current.dispatchAction({
                type: 'brush',
            });
            try {
                lineChartRef.current.setOption({
                    tooltip: {
                        show: false,
                    },
                });
            } catch (err) {}
        }
    }, []);
    const handleTouchMove = useCallback(() => {
        if (lineChartRef.current) {
            setTimeout(() => {
                try {
                    lineChartRef.current.setOption({
                        tooltip: {
                            show: true,
                        },
                    });
                } catch (err) {}
            });
        }
    }, []);
    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>{data.title}</div>
            {!hoverData ? (
                <div className={styles.select}>
                    {data.data.charts.map((item, index) => (
                        <div
                            key={index}
                            className={`${styles.item} ${
                                index === selectedIndex ? styles['selected-item'] : ''
                            }`}
                            onClick={() => setSelectedIndex(index)}
                        >
                            {item.title}
                        </div>
                    ))}
                </div>
            ) : (
                <div className={styles.data}>
                    {hoverData.map((item, index) => (
                        <div className={styles.item} key={index} style={{ color: item.color }}>
                            {item.value}
                        </div>
                    ))}
                </div>
            )}
            <div
                onTouchEnd={handleTouchEnd}
                onTouchStart={handleTouchMove}
                onMouseOut={handleTouchEnd}
                onMouseEnter={handleTouchMove}
            >
                <div
                    style={{ height: '300px', width: '100%' }}
                    className={styles['line-chart-content']}
                    ref={lineChartContentRef}
                />
            </div>
        </div>
    );
};
