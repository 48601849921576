import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import misc from '../../services/misc';
const LandingPage = () => {
    const params = useParams();
    useEffect(() => {
        misc.eventReport([
            {
                event_type: 1,
                event_id: 30025,
                params: { channel_name: params.channel_name },
                ts: +new Date(),
                sid: '',
            },
        ]).finally(() => {
            window.location.replace('https://ilookcap.com');
        });
    }, [params.channel_name]);

    return <div>正在跳转...</div>;
};

export default LandingPage;
