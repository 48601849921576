import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
/** rem适配 */
import 'lib-flexible';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import Routes from './routes/routes';
import { Provider } from 'react-redux';
import store from './store/store';
import './locale/config';
/** 魔改antd */
import './backupAntd.css';

/** 魔改antd mobile */
import './antd-mobile.css';

/** nprogress */
import 'nprogress/nprogress.css';

/** 全局样式 */
import './index.scss';

const App = () => {
    return (
        <Provider store={store}>
            <Router>
                <Routes></Routes>
            </Router>
        </Provider>
    );
};

ReactDOM.render(<App />, document.getElementById('root'));
