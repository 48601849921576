// import wx from 'weixin-js-sdk';
// import { getWxSignature } from './../../api/member/commonApi';
import { showToast } from './toast';
import Look_Cap from '../assets/images/lookcap.png';
import Share from '../services/share';
const wxsdk = {
    /**
     * @description 微信分享
     * @author Seven_G
     * @param {*} content 分享参数
     * @param {*} callback 回调函数
     */
    share(shareInfo, callback) {
        window.wx.checkJsApi({
            jsApiList: [
                'updateAppMessageShareData',
                'updateTimelineShareData',
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
            ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
            success: function (res) {
                // 以键值对的形式返回，可用的api值true，不可用为false
                // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
                let link = window.location.href;
                // 在这里设置默认分享的内容
                let content = {
                    title: '鹿可财富',
                    desc: '鹿可财富',
                    link: link,
                    imgUrl: Look_Cap,
                };
                if (shareInfo instanceof Object) {
                    content = shareInfo;
                }
                if (res.checkResult.updateAppMessageShareData) {
                    window.wx.updateAppMessageShareData({
                        title: content.title, // 分享标题
                        desc: content.desc, // 分享描述
                        link: content.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: 'http://oss.sharedaka.com/wechat/1620391284815-nxmssban.png', // 分享图标
                        success: function () {
                            // 设置成功
                            if (typeof callback == 'function') {
                                callback();
                            }
                        },
                    });
                }
                if (res.checkResult.updateTimelineShareData) {
                    console.log(content.imgUrl, 'content.imgUrl');
                    window.wx.updateTimelineShareData({
                        title: content.title, // 分享标题
                        link: content.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: 'http://oss.sharedaka.com/wechat/1620391284815-nxmssban.png',
                        success: function () {
                            // 设置成功
                            if (typeof callback == 'function') {
                                callback();
                            }
                        },
                    });
                }
                if (res.checkResult.onMenuShareAppMessage) {
                    window.wx.onMenuShareAppMessage(
                        {
                            title: content.title, // 分享标题
                            desc: content.desc, // 分享描述
                            link: content.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl: 'http://oss.sharedaka.com/wechat/1620391284815-nxmssban.png', // 分享图标
                        },
                        function () {
                            //这里是回调函数
                            if (typeof callback == 'function') {
                                callback();
                            }
                        },
                    );
                }
                if (res.checkResult.onMenuShareTimeline) {
                    window.wx.onMenuShareTimeline(
                        {
                            title: content.title, // 分享标题
                            link: content.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl: 'http://oss.sharedaka.com/wechat/1620391284815-nxmssban.png', // 分享图标
                        },
                        function () {
                            //这里是回调函数
                            if (typeof callback == 'function') {
                                callback();
                            }
                        },
                    );
                }
            },
        });
    },
    async init(content, callback) {
        const ua = navigator.userAgent.toLowerCase();
        window.wx.isWechat = false;
        if (ua.indexOf('micromessenger') > -1) {
            window.wx.isWechat = true;
        }

        /** 只有微信环境才init */
        if (window.wx.isWechat) {
            let link = window.location.href;
            // 注意在hash模式下，链接带有#，不进行切割时可能会导致签名错误（前端后端进行切割都可以），因为微信签名算法默认是不会将#号和井号之后的链接加入到算法当中
            link = window.location.href.split('#')[0];
            let params = {
                url: link,
            };
            Share.getWxSignature(params).then(res => {
                if (res && res.data && !res.data.error) {
                    window.wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: res.data.app_id, // 必填，公众号的唯一标识
                        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: res.data.nonce_str, // 必填，生成签名的随机串
                        signature: res.data.signature, // 必填，签名
                        jsApiList: [
                            'updateAppMessageShareData',
                            'updateTimelineShareData',
                            'onMenuShareAppMessage',
                            'onMenuShareTimeline',
                        ], // 必填，需要使用的JS接口列表
                    });
                    window.wx.ready(function () {
                        wxsdk.share(content, callback);
                    });
                    window.wx.error(function (err) {
                        console.log(err, 'wx.config出错了');
                        // showToast('wx.config出错了');
                    });
                }
            });
        }
    },
};
export default wxsdk;
