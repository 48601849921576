import Misc from '../services/misc';

class Tracker {
    /** 页面路径 */
    pathname = '';

    setPathname(pathname) {
        this.pathname = pathname;
    }

    /**
     * 传入params结构: {
     *      tp: '',
     *      dc: '',
     *      message: '',
     *      otherParams
     * }
     */
    send(params = {}) {
        let sendData = {
            /** page名字 */
            page_id: this.pathname,
            /** 1曝光   2点击 */
            event_type: params?.tp ?? '1',
            params: {
                /** 打点时间 */
                currentDate: +new Date(),
                /** 其它参数，任意传 */
                ...params,
            },
        };

        /** 打入Login信息 */
        const login = JSON.parse(localStorage.getItem('login') || '{}');
        if (Object.keys(login).length > 0) {
            sendData.params = {
                ...sendData.params,
                loginInfo: login,
            };
        }

        return Misc.eventReport(sendData)
            .then(() => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('trackPoint:', sendData);
                }
            })
            .catch(err => console.error(err));
    }
}

export default new Tracker();
