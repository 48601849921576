import s from './index.module.scss';
import contactUs from '../../../../images/contact-us.png';
import returnTop from '../../../../images/return_top.png';
import qrcode from '../../../../images/qrcode.png';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import misc from '../../../../services/misc';
function isWeiXin() {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
    } else {
        return false;
    }
}

const Assistant = ({ returnTopCall }) => {
    const { t, i18n } = useTranslation();

    const [show, setShow] = useState(true);
    const [showModal, setShowModal] = useState(false);
    let timer;
    const bindHandleScroll = () => {
        timer && clearTimeout(timer);
        timer = setTimeout(() => {
            handleScrollEnd();
        }, 500);
        setShow(false);
    };
    const handleScrollEnd = () => {
        setShow(true);
        clearTimeout(timer);
    };
    const mask = useRef(null);
    const dialog = useRef(null);
    useEffect(() => {
        window.addEventListener('scroll', bindHandleScroll);
        return () => {
            window.removeEventListener('scroll', bindHandleScroll);
        };
    }, []);
    useEffect(() => {
        mask?.current.addEventListener(
            'touchmove',
            e => {
                e.preventDefault();
            },
            { passive: false },
        );
        dialog?.current.addEventListener(
            'touchmove',
            e => {
                e.preventDefault();
            },
            { passive: false },
        );
    }, [mask, dialog]);
    return (
        <>
            <div
                className="custom-modal"
                id="picker"
                ref={mask}
                style={{ display: showModal ? 'flex' : 'none' }}
            >
                <div className="assistant-modal" ref={dialog}>
                    <div className="assistant-modal-title">{t('assistant-consult-us')}</div>
                    <img src={qrcode} alt="" className="assistant-modal-img" />
                    <div className="assistant-modal-subtitle">
                        {isWeiXin() ? t('assistant-wechat-desc') : t('assistant-notwechat-desc')}
                    </div>
                    {!isWeiXin() && (
                        <div className="assistant-modal-subtitle-wechat">{t('assistant-step')}</div>
                    )}
                    <div
                        className="assistant-modal-button"
                        onClick={e => {
                            setShowModal(false);
                            e.stopPropagation();
                        }}
                    >
                        {t('assistant-button')}
                    </div>
                </div>
            </div>
            <div className={s.container} style={{ right: `${show ? '0px' : 'calc(-80rem / 75)'}` }}>
                <img
                    src={contactUs}
                    alt=""
                    className={s.contactimg}
                    onClick={() => {
                        misc.eventReport([
                            {
                                event_type: 1,
                                event_id: 30021,
                                ts: +new Date(),
                                sid: '',
                            },
                        ]);
                        setShowModal(true);
                    }}
                />
                <div
                    className={s.top}
                    onClick={() => {
                        returnTopCall();
                    }}
                >
                    <img src={returnTop} alt="" />
                </div>
            </div>
        </>
    );
};
export default Assistant;
