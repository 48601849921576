import { useSelector } from 'react-redux';
import styles from './shares_card.module.scss';
import { createRef, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Flex, Modal } from 'antd-mobile';


export default (props) => {
    const [dropDown, setDropDown] = useState(false);
    const [shareList, setShareList] = useState([]);
    const [baseInfo, setBaseInfo] = useState({});

    useEffect(() => {
        // 遍历拿到 props
        let { secCates } = props;
        let data = _.filter(secCates, item => {
            return item.name === '股权'
        })[0];
        // hold_value 昨日总市值
        // hold_value_diff 持仓市值比例
        let { positions, hold_value, hold_value_diff, hold_value_currency } = data;

        // 按市值排序
        let a = _.sortBy(positions, (item) => {
            return -item.c_hold_value;
        })
        setShareList(a);
        let baseInfo = {
            hold_value,
            hold_value_diff,
            hold_value_currency,
        }
        setBaseInfo(baseInfo);
    }, []);

    const isNegative = v => {
        return String(v).indexOf('-') >= 0;
    };

    const numberFormat = function (value) {
        if (_.isUndefined(value)) {
            return 0;
        }
        let param = {};
        let neg = false;
        if (isNegative(value)) {
            neg = true;
            value = -value;
        }
        let k = 10000;
        let sizes = ['', '万', '亿', '万亿'];
        let i;
        if (value < k) {
            param.value = value.toFixed(2);
            param.unit = '';
        } else {
            i = Math.floor(Math.log(value) / Math.log(k));
            param.value = ((value / Math.pow(k, i))).toFixed(2);
            param.unit = sizes[i];
        }
        let output = `${neg ? -param.value : param.value}${param.unit}`;
        return output;
    };

    const changeDropDown = () => {
        setDropDown(!dropDown);
    };

    const isDataEmpty = () => _.isEmpty(shareList);

    const drowDownItem = <div
        className={styles.fold_button}
        onClick={changeDropDown}
    >
        <span
            className={dropDown ? styles.fold_up : styles.upfold_down}
        >
        </span>
        {
            dropDown ? <span>收起列表</span> : <span>显示全部</span>
        }
    </div>

    return isDataEmpty() ? <div></div> : <div key="shares_card" className={styles.container} style={{ color: 'white' }}>
        <div className={styles.total_market_value}>
            <div className={styles.title}>
                昨日总市值 ({baseInfo.hold_value_currency})
        </div>
            <div>
                <span className={styles.number}>
                    {
                        baseInfo.hold_value
                    }
                </span>
                <span className={`${styles.rate} ${isNegative(baseInfo.hold_value_diff) ? styles.value_descend : styles.value_increment}`}>
                    (
                        {
                        baseInfo.hold_value_diff
                    }
                    )
            </span>
            </div>
        </div>

        <Flex className={styles.title_bar}>
            <Flex.Item>
                <span className={styles.margin_left_30}>
                    名称
                </span>
            </Flex.Item>
            <Flex.Item align='end'>
                持仓 | 市值
            </Flex.Item>
            <Flex.Item align='end'>
                收盘价 | 成本
            </Flex.Item>
            <Flex.Item align='end'>
                <span className={styles.margin_right_20}>
                    浮动盈亏
                </span>
            </Flex.Item>
        </Flex>

        <div className={styles.share_list}>
            {
                _.map(shareList, (item, index) => {
                    if (!dropDown && index >= 2) {
                        return null;
                    }
                    return <div key={index}>
                        <Flex className={styles.share_item}>
                            <Flex.Item>
                                <div className={`${styles.limit_width} ${styles.margin_left_30} ${styles.color_white}`}>
                                    {
                                        item.symbol
                                    }
                                </div>
                            </Flex.Item>
                            <Flex.Item align='end'>
                                <div className={styles.color_white}>
                                    {
                                        item.aggr_balance
                                    }
                                </div>
                                <div>
                                    {
                                        item.hold_value
                                    }
                                </div>
                            </Flex.Item>
                            <Flex.Item align='end'>
                                <div className={styles.color_white}>
                                    {
                                        item.close_price
                                    }
                                </div>
                                <div>
                                    {
                                        item.cost_price
                                    }
                                </div>
                            </Flex.Item>
                            <Flex.Item align='end'>
                                <div className={`${styles.margin_right_20} ${isNegative(item.earn) ? styles.value_descend : styles.value_increment}`}>
                                    <div>

                                        {
                                            item.earn
                                        }
                                    </div>
                                    <div>
                                        {item.earn_rate}
                                    </div>
                                </div>
                            </Flex.Item>
                        </Flex>
                    </div>
                })
            }
            {
                shareList.length > 2 && drowDownItem
            }
        </div>
    </div>
};
