import React, { useEffect, useRef, useState, useCallback } from 'react';
import './OfficialMobile.scss';
import { Drawer } from 'antd';
import { ReqImg } from './official';
import DownloadMobile from '../download/downloadMobile.jsx';
import { getBaikeUrl } from '../../utils/constants.js';
import Advantage from './component/advantage-mobile';
import Support from './component/support-mobile';
import Recommend from './component/recommend-mobile';
import About from './component/about-mobile';
import TeamLeader from './component/TeamLeader-mobile';
import Bank from './component/bank-mobile';
import Company from './component/company-mobile';
import Contact from './component/contact-mobile';
import Assistant from './component/assistant-mobile';
import Aum from './component/aum-mobile';
import Technology from './component/technology-mobile';
import { useTranslation } from 'react-i18next';
import misc from '../../services/misc';
const numMap = {
    top: '首页',
    'baike-link': '财富百科',
    team: '团队成员',
    contact: '联系我们',
    download: '下载APP',
    business: '鹿可业务',
    about: '关于鹿可',
    'top-div-img': '鹿可logo',
};

const symbols = [
    {
        description: '标普 500',
        proName: 'SP:SPX',
    },
    {
        description: '恒生指数',
        proName: 'HSI:HSI',
    },
    {
        description: '道琼斯指数',
        proName: 'DJ:DJI',
    },
    {
        description: '恒生科技指数',
        proName: 'HSI:HSTECH',
    },
    {
        description: '沪深 300 指数',
        proName: 'SSE:000300',
    },
    {
        description: 'BTC',
        proName: 'COINBASE:BTCUSD',
    },
    {
        description: 'ETH',
        proName: 'COINBASE:ETHUSD',
    },
    {
        description: 'QQQ',
        proName: 'NASDAQ:QQQ',
    },
    {
        description: 'SOXX半导体指数',
        proName: 'NASDAQ:SOXX',
    },
    {
        description: 'CSI中概互联网指数',
        proName: 'AMEX:KWEB',
    },
    {
        description: 'XLP美股消费ETF',
        proName: 'AMEX:XLP',
    },
    {
        description: 'XLV 美股医疗 ETF',
        proName: 'AMEX:XLV',
    },
];

export default function OfficialMobile() {
    const [showMenu, setShowMenu] = useState(false);
    const [curAnchor, setCurAnchor] = useState(location.hash);
    const [showBg, setShowBg] = useState(false);
    const [currentKey, setCurrentKey] = useState(0);
    const { t, i18n } = useTranslation();
    const hasLoadBaike = useRef(false);
    const isEN = i18n.language === 'en';
    const isZH = i18n.language === 'zh';

    const [showBaike, setShowBaike] = useState(false);
    useEffect(() => {
        const handleOnScroll = () => {
            setShowBg(showBaike ? true : window.scrollY > 128);
        };
        window.addEventListener('scroll', handleOnScroll, true);
        return () => {
            window.removeEventListener('scroll', handleOnScroll);
        };
    }, [showBaike]);
    useEffect(() => {
        if (location.hash) {
            let path =
                location.hash.indexOf('wechat') > -1
                    ? location.hash.split('?wechat=1')[0]
                    : location.hash;
            setCurAnchor(path);
            const id = path.replace('#', '');
            let taskId = setInterval(() => {
                const element = document.getElementById(id);
                if (element) {
                    clearInterval(taskId);
                    element.scrollIntoView();
                }
            }, 200);
        } else {
            setCurAnchor('#top');
        }
    }, [location.hash]);

    const MenuItem = props => {
        const { text, anchor, href } = props;
        let showHightlight = showBaike ? anchor === undefined : curAnchor === anchor;
        return (
            <a
                className="menu-item"
                href={anchor ?? ''}
                // target={href ? '_blank' : '_self'}
                onClick={e => {
                    misc.eventReport([
                        {
                            event_type: 2,
                            event_id: 30004,
                            params: { title: numMap[(anchor ?? '#baike-link').substring(1)] },
                            ts: +new Date(),
                            sid: '',
                        },
                    ]);

                    setShowMenu(false);
                    if (href) {
                        setShowBaike(true);
                        setShowBg(true);
                        setCurrentKey(e => e + 1);
                        e.preventDefault();
                    } else {
                        setShowBaike(false);
                    }
                }}
            >
                <span className={`${showHightlight && 'highlighted'}`}>{text}</span>
                <div className="divider" />
            </a>
        );
    };
    const changeLanguage = useCallback(
        lang => {
            misc.eventReport([
                {
                    event_type: 2,
                    event_id: 30020,
                    params: { current: lang },
                    ts: +new Date(),
                    sid: '',
                },
            ]);
            i18n.changeLanguage(lang);
            window.localStorage.setItem('lk_lang', lang);
            setShowMenu(false);
        },
        [i18n],
    );
    return (
        <div className="mobile-body" style={{ position: 'relative', height: '100%' }}>
            <div id="top" className="top-anchor" />
            <div id="download" className="top-anchor" />
            <Drawer
                visible={showMenu}
                placement="top"
                className="drawer"
                height="auto"
                closable={false}
                onClose={() => setShowMenu(false)}
                destroyOnClose
            >
                <MenuItem text={t('nav-Home')} anchor="#top" />
                {isZH && <MenuItem text={t('nav-Services')} anchor="#business" />}
                {isEN && <MenuItem text={t('nav-aum')} anchor="#aum" />}
                {isEN && <MenuItem text={t('nav-tech')} anchor="#tech" />}
                <MenuItem text={t('nav-About')} anchor="#about" />
                {isEN && <MenuItem text={t('nav-partners')} anchor="#partners" />}
                {isZH && <MenuItem text={t('nav-Team')} anchor="#team" />}
                <MenuItem text={t('nav-Contact')} anchor="#contact" />
                <MenuItem text={t('nav-Insights')} href={getBaikeUrl()} />
                <MenuItem text={t('nav-Download')} anchor="#download" />
                <div className="drawer-bottom-area">
                    <div
                        className="drawer-bottom-area-chinese"
                        onClick={() => changeLanguage('zh')}
                    >
                        中文
                    </div>
                    <div className="drawer-bottom-area-line"></div>
                    <div
                        className="drawer-bottom-area-english"
                        onClick={() => changeLanguage('en')}
                    >
                        EN
                    </div>
                </div>
                {/* <MenuItem text="首页" anchor="#top" />

                <MenuItem text="鹿可业务" anchor="#business" />
                <MenuItem text="关于鹿可" anchor="#about" />
                <MenuItem text="团队成员" anchor="#team" />
                <MenuItem text="联系我们" anchor="#contact" />
                <MenuItem text="财富百科" href={getBaikeUrl()} />
                <MenuItem text="下载 App" anchor="#download" /> */}
            </Drawer>
            <div className={`section-header ${showBg ? 'section-header-white' : ''}`}>
                <a
                    href="#top"
                    onClick={() => {
                        misc.eventReport([
                            {
                                event_type: 2,
                                event_id: 30004,
                                params: { title: '鹿可logo' },
                                ts: +new Date(),
                                sid: '',
                            },
                        ]);
                    }}
                >
                    <img
                        src={ReqImg(
                            isZH
                                ? './mobile_images/logo_header.png'
                                : './mobile_images/logo_header_en.png',
                        )}
                        alt="Look Capital Logo"
                        className="logo"
                    />
                </a>
                <img
                    src={ReqImg(
                        showMenu
                            ? './mobile_images/ic_global_menu_close.svg'
                            : './mobile_images/ic_global_menu.svg',
                    )}
                    alt="Menu Icon"
                    className="menu"
                    onClick={() => setShowMenu(!showMenu)}
                />
            </div>
            {(showBaike || hasLoadBaike.current) && (
                <iframe
                    scrolling="auto"
                    src={getBaikeUrl() + '?hidenav=true#/'}
                    // key={`iframekeyMobile${currentKey}`}
                    width={'100%'}
                    height={`100%`}
                    onLoad={e => {
                        hasLoadBaike.current = true;
                    }}
                    style={{
                        borderWidth: '0',
                        marginTop: '0.4rem',
                        ...(showBaike ? {} : { display: 'none' }),
                    }}
                ></iframe>
            )}
            {!showBaike && (
                <div className="content" style={{ backgroundColor: '#f7f7f8' }}>
                    {curAnchor === '#download' ? (
                        <DownloadMobile />
                    ) : (
                        <>
                            <div className={`poster ${isEN && 'posterEn'}`}>
                                <img
                                    src={ReqImg(
                                        i18n.language === 'en'
                                            ? './mobile_images/home_banner_mobile.png'
                                            : './mobile_images/text_home_poster.png',
                                    )}
                                />
                            </div>
                            {isZH && <Advantage></Advantage>}
                            {isZH && <Support></Support>}
                            {isEN && <Aum />}
                            {isEN && <Technology />}
                            {/* <Recommend></Recommend> */}
                            <About></About>
                            {isZH && <TeamLeader></TeamLeader>}
                            <Bank></Bank>
                            {/* <Company></Company> */}

                            <Contact></Contact>
                            <Assistant
                                returnTopCall={() => {
                                    misc.eventReport([
                                        {
                                            event_type: 2,
                                            event_id: 30024,
                                            ts: +new Date(),
                                            sid: '',
                                        },
                                    ]);
                                    let a = document.getElementById('top');
                                    a.scrollIntoView(true);
                                }}
                                showReturn
                            ></Assistant>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
