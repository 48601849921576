import { useSelector } from 'react-redux';
import styles from './cash_card.module.scss';
import { createRef, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Flex, Modal } from 'antd-mobile';
import USA_FLAG from '../../../images/usa_flag.png';
import HK_FLAG from '../../../images/hk_flag.png';
import CHN_FLAG from '../../../images/chn_flag.png';
import SG_FLAG from '../../../images/sg_flag.png';
import LEFT_ICON from '../../../images/left.png';
import USD_TEXT from '../../../images/USD_text.png';
import HKD_TEXT from '../../../images/HKD_text.png';
import CHN_TEXT from '../../../images/CHN_text.png';
import SGD_TEXT from '../../../images/SGD_text.png';


export default (props) => {
    // 当前 modal 值
    const [modalValue, setModalValue] = useState([]);

    const [showModal, setShowModal] = useState(false);

    // 现金数据
    const [resData, setResData] = useState({});

    // 现金
    const [cashData, setCashData] = useState({});
    // 贷款
    const [loanData, setLoanData] = useState({});
    const [isReady, setIsReady] = useState(false);
    const [emptyData, setEmptyData] = useState(false);

    // 货币映射
    const currencyMapper = {
        'USD': {
            name: '美元',
            img: USA_FLAG,
            text: USD_TEXT,
            rate: 1,
        },
        'CNH': {
            name: '人民币',
            img: CHN_FLAG,
            text: CHN_TEXT,
            rate: 6.4583,
        },
        'CHN': {
            name: '人民币',
            img: CHN_FLAG,
            text: CHN_TEXT,
            rate: 6.4583,
        },
        'SGD': {
            name: '新币',
            img: SG_FLAG,
            text: SGD_TEXT,
            rate: 1.3481,
        },
        'HKD': {
            name: '港币',
            img: HK_FLAG,
            text: HKD_TEXT,
            rate: 7.7763,
        },
    };

    useEffect(() => {
        let { secCates } = props;
        let data = _.filter(secCates, item => {
            return item.name === '现金'
        })[0];
        setResData(data);
        let { positions } = data;
        if (!_.isEmpty(positions)) {
            let cashData = positions?.[0] || {};
            setCashData(cashData);
            let loanData = positions?.[1] || {};
            setLoanData(loanData);
        } else {
            setEmptyData(true);
        }

        setIsReady(true);
    }, []);

    const isNegative = v => {
        return v < 0;
    };
    const numberFormat = function (value) {
        if (_.isUndefined(value)) {
            return 0;
        }
        let param = {};
        let neg = false;
        if (isNegative(value)) {
            neg = true;
            value = -value;
        }
        let k = 10000;
        let sizes = ['', '万', '亿', '万亿'];
        let i;
        if (value < k) {
            param.value = value.toFixed(2);
            param.unit = '';
        } else {
            i = Math.floor(Math.log(value) / Math.log(k));
            param.value = ((value / Math.pow(k, i))).toFixed(2);
            param.unit = sizes[i];
        }
        let output = `${neg ? -param.value : param.value}${param.unit}`;
        return output;
    }

    let hasToken = useSelector(state => state.token.hasToken);

    // 按汇率转换成目标数字
    const accountConvert = (number, type = 'USD') => {
        let output = number * currencyMapper[type].rate;
        return numberFormat(output)
    };

    const accountUnit = (data = {}) => {
        const empty = parseFloat(data?.c_hold_value) === 0;
        return !empty && <div>
            <div>
                <div className={styles.acount_title}>
                    <span className={styles.accoun_type}>
                        币种
                    </span>
                    <div className={`${styles.float_right}`}>
                        金额
                    </div>
                </div>
                <div className={styles.acount_unit}>
                    <Flex key={data?.c_hold_value}>
                        <Flex.Item style={{ flex: 2 }}>
                            <span>
                                {data.symbol || '-'}
                            </span>

                        </Flex.Item>
                        <Flex.Item align='end' style={{ flex: 4 }}>
                            <Flex className="click_hold_value_flex">
                                <Flex.Item align='end' style={{ flex: 3 }}>
                                    <div className={styles.hold_value}>
                                        {
                                            data.book_value
                                        }
                                        <img className={`${window.screen.width > 430 ? styles.superscript_bigger : styles.superscript}`} src={currencyMapper[data.isin].text}></img>
                                    </div>
                                </Flex.Item>
                                <Flex.Item align='end' style={{ flex: 1 }}>
                                    <span onClick={openModal.bind(this, data.book_value_exchange)}>
                                        <img className={styles.click_button} src={LEFT_ICON}></img>
                                    </span>
                                </Flex.Item>
                            </Flex>
                        </Flex.Item>
                    </Flex>
                </div>
            </div>
        </div>
    };

    const renderAccounts = (data) => {
        const sub = data?.sub_positions || [];
        const newData = [sub.slice(0, 2), sub.slice(2, 4)];
        const onlyOneLine = _.isEmpty(newData[1])
        return <div>
            {_.map(newData, (line, index) => {
                return <Flex key={`${index}`} className={index === 0 && !onlyOneLine && styles.account_line}>
                    {
                        _.map(line, (item) => <Flex.Item>
                            {
                                accountUnit(item || {})
                            }
                        </Flex.Item>
                        )
                    }
                </Flex>
            })}
        </div>
    };

    // 渲染现金 / 贷款行
    const renderCashLoan = (data) => {
        return !_.isEmpty(data) && <div className={styles.cash_loan_container}>
            <div className={styles.cash_loan_title}>
                <span className={styles.title}>
                    {data.symbol}
                </span>
                <div className={`${styles.float_right} ${styles.cash}`}>
                    {
                        data.hold_value
                    }
                </div>
            </div>
            {
                // 渲染多币种
                renderAccounts(data)
            }
        </div>
    };

    const openModal = (data) => {
        setModalValue(data);
        toggleModal();
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const calculateRate = (leftType, rightType) => {
        // 1 rmb = 0.1547 usd = 1 / 美元汇率
        // 1 hk = 0.1286 usd = 1 / 美元汇率
        let leftUsd = 1 / currencyMapper[leftType].rate;
        let rightUsd = 1 / currencyMapper[rightType].rate;
        return (rightUsd / leftUsd).toFixed(4);
    }

    const renderCurrencyLine = (data, isCurrent = false) => {
        let m = currencyMapper[data.to_currency];
        let imgSrc = m.img;

        return <div className="country_line">
            <Flex key={`${data.to_currency}_${data.to_name}`}>
                <Flex.Item style={{ flex: 1 }}>
                    <img className="country_flag" src={imgSrc}></img>
                </Flex.Item>
                <Flex.Item style={{ flex: 5 }}>
                    <div className="margin-left-20">
                        <div className={`account_value ${isCurrent && "current_color"}`}>
                            {data.to_amount} {data.to_name} {data.to_currency}
                        </div>
                        <div className={`account_rate ${isCurrent && "current_color"}`}>
                            {
                                isCurrent ? '当前币种' : `1 ${data.fromName} = ${data.exchange_rate} ${data.to_name}`
                            }
                        </div>

                    </div>
                </Flex.Item>
            </Flex>


        </div>
    }

    const renderModal = () => {
        // 渲染 modal 里的数据
        return <Modal
            visible={showModal}
            transparent
            maskClosable={true}
            onClose={() => { toggleModal() }}
            className="country_account_modal"
        >
            {/* 先渲染当前, 再渲染其他 */}
            {
                _.map(modalValue, (item, index) => {
                    return renderCurrencyLine(item, index === 0);
                })
            }

            <div className="tips">
                换算金额通过非实时汇率计算得出。存在误差，仅作参考。
            </div>
        </Modal>
    };


    // 币种计算单元
    return (
        !emptyData && !!isReady && <div key="cash_card" className={styles.container} style={{ color: 'white' }}>
            {/* <div className={styles.bar}></div> */}
            <div className={styles.total_market_value}>
                <div className={styles.title}>
                    现金总额 {resData.hold_value_currency}
                </div>
                <div className={styles.number}>
                    {
                        resData.hold_value
                    }
                </div>
            </div>
            {renderModal()}
            {renderCashLoan(cashData)}
            {renderCashLoan(loanData)}
        </div >
    );
};
