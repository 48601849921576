import { createRef, useCallback, useEffect, useState } from 'react';
import styles from './feedback.module.scss';
import Header from '../../layouts/header/header';
import BasicWrapper from '../../components/BasicWrapper/BasicWrapper';
import { useHistory, useLocation } from 'react-router';
import qs from 'qs';
import Feedback from '../../services/feedback';
import Success from '../../images/feedback_success.png';
export default () => {
    const [num, setNum] = useState(0);
    const [text, setText] = useState('');
    const [submitFlag, setSubmitFlag] = useState(false);

    const location = useLocation();
    const history = useHistory();

    const queryStr = location.search.slice(1);
    const query = qs.parse(queryStr);

    const onClickBack = () => {
        history.replace(query.from || '/property');
    };

    const onChange = e => {
        const content = e.target.value;
        let numBytes = checkLength(content);
        numBytes = parseInt(numBytes / 2);
        setNum(numBytes);
        setText(content);
    };
    const checkLength = function (strTemp) {
        var i, sum;
        sum = 0;
        for (i = 0; i < strTemp.length; i++) {
            if (strTemp.charCodeAt(i) >= 0 && strTemp.charCodeAt(i) <= 255) {
                sum = sum + 1;
            } else {
                sum = sum + 2;
            }
        }
        return sum;
    };

    const onSubmit = () => {
        if (submitFlag) {
            history.replace(query.from || '/property');
            return;
        }
        const params = {
            note: text,
            page_id: query.from,
        };
        Feedback.postFeedback(params).then(res => {
            if (res.code === 0) {
                setSubmitFlag(true);
            }
        });
    };

    return (
        <BasicWrapper>
            <Header onClickBack={onClickBack} needBack={true} title={'意见反馈'}></Header>
            <div className={styles.container}>
                {!submitFlag && (
                    <textarea
                        className={styles['textarea']}
                        id=""
                        cols="30"
                        rows="10"
                        maxLength="100"
                        onChange={onChange}
                        placeholder="请输入您的宝贵意见..."
                    ></textarea>
                )}
                {!!submitFlag && (
                    <div className={styles['success__wrap']}>
                        <img src={Success} alt="" />
                        <div className={styles['text']}>意见提交成功</div>
                        <div>感谢您的宝贵意见！</div>
                    </div>
                )}
                {!submitFlag && <div className={styles['num']}>{num}/100</div>}
                <div className={styles['submit__btn']} onClick={onSubmit}>
                    {submitFlag ? '返回反馈前页面' : '提交'}
                </div>
            </div>
        </BasicWrapper>
    );
};
