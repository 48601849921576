import React, { useState, useMemo, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import HomeTitle from '../HomeTitle';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import map from '../../../../images/homeMap.png';
import bg from '../../../../images/aum-bg.png';
import './index.scss'


export default function Aum(props) {
    const { t, i18n } = useTranslation();
    const is1440 = useSelector(state => state.windowSizeReducer.mobileType === 1440);

    return <div className={`aum-contain ${is1440 && 'aum-contain1440'}`} id="aum">
        <HomeTitle title={t('aum-title')}></HomeTitle>
        <div className='aum-content'>
            <div className='aum-content-des1'>{t('aum-des1')}</div>
            <div className='aum-content-des2'>{t('aum-des2')}</div>
            <div className='aum-content-des3'>{t('aum-des3')}</div>
            <div className='aum-content-des4'>{t('aum-des4')}</div>
        </div>

    </div>
}