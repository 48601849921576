import axios from '../utils/o-axios';

class Feedback {
    postFeedback = data => {
        return axios.post('/v1/misc/feedback', {
            data,
        });
    };
}

export default new Feedback();
