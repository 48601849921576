import styles from './PortalTabbar.module.scss';
import MINE_ACTIVE_ICON from '../../assets/images/mine-active.png';
import MINE_ICON from '../../assets/images/mine.png';
import WALLET_ICON from '../../assets/images/wallet.png';
import WALLET_ACTIVE_ICON from '../../assets/images/wallet-active.png';
import { useLocation, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const TABS_INFO = [
    {
        activeIcon: WALLET_ACTIVE_ICON,
        unActiveIcon: WALLET_ICON,
        name: '资产',
        toPath: '/property',
    },
    {
        activeIcon: MINE_ACTIVE_ICON,
        unActiveIcon: MINE_ICON,
        name: '我的',
        toPath: '/mine',
    },
];

const TabItem = ({ tab }) => {
    const history = useHistory();
    const location = useLocation();
    const [currentPathname, setCurrentPathname] = useState('');
    let hasToken = useSelector(state => state.token.hasToken);
    useEffect(() => {
        setCurrentPathname(location.pathname);
    }, [location.pathname]);

    /**
     * 如果当前path包含了tabBar的path，则为active
     */
    const checkIsMatch = (tabPath, currentPath) => {
        return currentPath.indexOf(tabPath) !== -1;
    };

    const onClickTabItem = path => {
        if (!hasToken && path === '/mine') {
            history.replace(`/login?from=/property`);
        } else {
            history.replace(path);
        }
    };

    return (
        <div
            className={`${styles['tab-item']} ${
                checkIsMatch(tab.toPath, currentPathname)
                    ? styles['is-active']
                    : styles['non-active']
            }`}
            onClick={() => onClickTabItem(tab.toPath)}
        >
            <div className={styles['item-wrapper']}>
                <img
                    className={styles['icon']}
                    src={
                        checkIsMatch(tab.toPath, currentPathname)
                            ? tab.activeIcon
                            : tab.unActiveIcon
                    }
                />
                <div className={styles['tab-name']}>{tab.name}</div>
            </div>
        </div>
    );
};

const Tabs = ({ children }) => {
    return <div className={styles['tabs-container']}>{children}</div>;
};

export default () => {
    return (
        <Tabs>
            {TABS_INFO.map(tab => (
                <TabItem key={tab.name} tab={tab} />
            ))}
        </Tabs>
    );
};
