import style from './index.module.scss';
import misc from '../../../../services/misc';
import minibaike from '../../../../images/mini-baike.png';
import moreicon from '../../../../images/more.png';
import home from '../../../../services/home';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const Recommend = () => {
    const [data, setData] = useState([]);
    const { t, i18n } = useTranslation();
    const is1024 = useSelector(state => state.windowSizeReducer.mobileType === 1024);

    useEffect(() => {
        home.getArticle().then(res => {
            if (res.code === 0 && res.data !== undefined) {
                setData(res.data);
            }
        });
    }, [i18n.language]);

    return (
        <div className={`${style.content} ${is1024 ? style.content1024 : ''} `}>
            {data.map(item => (
                <div
                    key={item.id}
                    className={style.item}
                    onClick={() => {
                        misc.eventReport([
                            {
                                event_type: 2,
                                event_id: 30023,
                                params: { title: item.doc_title },
                                ts: +new Date(),
                                sid: '',
                            },
                        ]);
                        window.open(item.redirectUrl, '_blank');
                    }}
                >
                    <img src={item.topicTitleImg} alt="" className={style.img} />
                    <div
                        className={`${style.textContent} ${
                            i18n.language === 'en' ? style.textContentEn : ''
                        }`}
                    >
                        <div className={style.topicIcon}>
                            <div className={style.topicIconTitle}>{item.name}</div>
                            <img src={minibaike} alt="" className={style.topicIconIcon} />
                        </div>
                        <div className={style.title}>{item.doc_title}</div>
                        <div className={style.body}>{item.summary}</div>
                        <div className={style.more}>
                            <span>{t('view-more')}</span>
                            <img src={moreicon} alt="" className={style.imgmore} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
export default Recommend;
