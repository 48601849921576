import './index.scss';
import logoFotter from '../../../../images/logo_footer_mobile.png';
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="lk-mobile-contack-info">
                <div className="lk-mobile-contack-footer">
                    <img src={logoFotter} alt="" className="lk-mobile-contack-footer-img" />
                    <div className="lk-mobile-contack-footer-content">
                        <div className="lk-mobile-contack-footer-text">
                            2024 LOOK CAPITAL © Copyright
                        </div>
                        <div className="lk-mobile-contack-footer-text">
                            京 ICP 备 2021012808 号 -1
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Contact;
