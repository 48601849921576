import axios from '../utils/o-axios'

class Security {
    getSecuritySubcate = (data) => {
        return axios.post('/v1/security/sub_cate', {
            data,
        });
    }

    getSecurityDetail = (data) => {
        return axios.post('/v1/security/detail', {
            data,
        });
    }
}

export default new Security()


