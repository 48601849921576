import { createRef, useCallback, useEffect, useState } from 'react';
import styles from './portal_product_list.module.scss';
import Header from '../../layouts/header/header';
import BasicWrapper from '../../components/BasicWrapper/BasicWrapper';
import { useHistory, useLocation, useParams } from 'react-router';
import Security from '../../services/security';
import { showFail, showToast } from '../../utils/toast';
import { RightOutlined } from '@ant-design/icons';
import Look_Cap from '../../assets/images/lookcap.png';
import wxJsSdk from '../../utils/wxJsSdk';
import FeedbackBtn from '../../components/FeedbackBtn/FeedbackBtn';
import ListBgImg from '../../images/portal_list_bgp.png';
import { useSelector } from 'react-redux';

export default () => {
    const [secSummaries, setSecSummaries] = useState([]);
    const [sub_sec_cate, setSubSecCate] = useState([]);
    const [isReady, setIsReady] = useState(false);
    const history = useHistory();
    const params = useParams();
    const location = useLocation();
    let hasToken = useSelector(state => state.token.hasToken);

    useEffect(() => {
        Security.getSecuritySubcate({
            sub_sec_cate_id: params.sub_sec_cate_id,
        })
            .then(res => {
                setSecSummaries(res.data.sec_summaries);
                setSubSecCate(res.data.sub_sec_cate);
                setIsReady(true);
                wxJsSdk.init({
                    title: res.data.sub_sec_cate?.name, // 分享标题
                    desc: '鹿可财富产品列表', // 分享描述
                    link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: Look_Cap, // 分享图标});
                });
            })
            .catch(err => {
                console.error(err);
                showToast(err.msg);
            });
    }, [params.sub_sec_cate_id]);

    const onClickNav2Detail = id => {
        history.replace(`/detail/${id}`);
    };

    const onClickBack = () => {
        history.replace('/property');
    };

    return (
        <>
            {isReady && (
                <BasicWrapper>
                    <Header
                        headerBgColor={'#000000'}
                        onClickBack={onClickBack}
                        needBack={true}
                        title="产品列表"
                    ></Header>
                    <div
                        className={styles.container}
                        style={{ backgroundImage: `url(${ListBgImg})` }}
                    >
                        <div className={styles.box}>
                            <div className={styles.title}>{sub_sec_cate.name}</div>
                            <div className={styles.desc}>{sub_sec_cate.desc}</div>
                            {!!sub_sec_cate.notice?.[0] && (
                                <div className={styles.notice}>* {sub_sec_cate.notice?.[0]}</div>
                            )}
                        </div>

                        <div className={styles.detail__wrap}>
                            {secSummaries?.map((sec, index) => {
                                return (
                                    <div
                                        onClick={() => {
                                            onClickNav2Detail(sec.sec_id);
                                        }}
                                        key={sec.sec_id}
                                        className={`${styles.item}`}
                                    >
                                        <div className={styles.item__head}>
                                            <div className={styles.name}>{sec.name}</div>

                                            <RightOutlined style={{ fontWeight: '600' }} />
                                        </div>
                                        <div className={styles.item__bottom}>
                                            <div className={styles.value__wrap}>
                                                {sec.summary.values.map(key => {
                                                    return (
                                                        <div
                                                            key={key}
                                                            className={styles.item__left__key}
                                                        >
                                                            {key}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className={styles.key__wrap}>
                                                {sec.summary.keys.map(key => {
                                                    return (
                                                        <div
                                                            key={key}
                                                            className={styles.item__left__key}
                                                        >
                                                            {key}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <FeedbackBtn style={{ margin: '0.426666667rem 0 0' }} />
                    </div>
                </BasicWrapper>
            )}
        </>
    );
};
