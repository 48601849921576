import { useHistory, useParams } from 'react-router-dom';
import { Input, Select } from 'antd';
const { Option } = Select;
import { useRef, useCallback, useEffect, useState } from 'react';
import styles from './login.module.scss';
import Header from '../../layouts/header/header';
import axios from '../../utils/o-axios';
import { hideLoading, showLoading, showToast } from '../../utils/toast';
import BasicWrapper from '../../components/BasicWrapper/BasicWrapper';
import { List, Picker } from 'antd-mobile';
import Misc from '../../services/misc';
import User from '../../services/user';
const SMS_INTERVAL = 60;
import { setHasToken, setLogin } from '../../actions/actions';
import { connect } from 'react-redux';
import track from '../../utils/track';
import qs from 'qs';

const Login = ({ setLogin, setHasToken }) => {
    const [tel, setTel] = useState(null);
    const [countryCode, setCountryCode] = useState('+86');
    const timerRef = useRef(0);
    const [retrySmsTime, setRetrySmsTime] = useState(0);
    const [hasSendSmsCode, setHasSendSmsCode] = useState(false);
    const [smsCode, setSmsCode] = useState(null);

    const queryStr = location.search.slice(1);
    const query = qs.parse(queryStr);

    let timer = useRef(null);
    const initSmsTimer = useCallback(() => {
        timer.current = setInterval(() => {
            if (timerRef.current === 0) {
                clearInterval(timer.current);
            } else {
                timerRef.current -= 1;
                setRetrySmsTime(v => v - 1);
            }
        }, 1000);
    }, [timer]);

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            console.log('localStorageLogin:', localStorage.getItem('login'));
        }
        return () => clearInterval(timer.current);
    }, []);

    const history = useHistory();

    const onChangeTel = event => {
        setTel(event.target.value);
    };

    const onChangeSmsCode = event => {
        let value = event.target.value;
        if (event.target.value.length > 6) {
            value = value.slice(0, 6);
        }
        setSmsCode(value);
    };

    /**
     * 点击授权手机号
     */
    const onClickNextStep = () => {
        if (!tel) return;
        if (!smsCode) return;
        showLoading();
        const fullTel = `${countryCode ? countryCode : '+86'}${tel}`;

        track.send({
            tp: '2',
            dc: '01',
            message: '授权手机号按钮点击',
            data: {
                fullTel,
            },
        });

        User.login({ mobile_number: fullTel, check_code: smsCode })
            .then(res => {
                hideLoading();
                setLogin(res.data.login);
                setHasToken(true);
                localStorage.setItem('login', JSON.stringify(res.data.login));
                const lastPathname = localStorage.getItem('lastPathname');
                localStorage.removeItem('lastPathname');
                history.replace(lastPathname || '/property');
            })
            .catch(err => {
                showToast(err.msg);
            });

        /**
         * mock login 老token
         */
        // hideLoading();
        // setLogin({ uid: 'u3cad4f35854ea52', token: '1f19d40', login_at: 1619597708965 });
        // setHasToken(true);
        // localStorage.setItem(
        //     'login',
        //     JSON.stringify({ uid: 'u3cad4f35854ea52', token: '1f19d40', login_at: 1619597708965 }),
        // );
        // const lastPathname = localStorage.getItem('lastPathname');
        // localStorage.removeItem('lastPathname');
        // history.replace(lastPathname || '/property');

        /**
         * mock login
         */
        // const token = { uid: 'u3cad4f35854ea52', token: '3fad757', login_at: 1622626758492 };
        // hideLoading();
        // setLogin(token);
        // setHasToken(true);
        // localStorage.setItem('login', JSON.stringify(token));
        // const lastPathname = localStorage.getItem('lastPathname');
        // localStorage.removeItem('lastPathname');
        // history.replace(lastPathname || '/property');
    };

    const onInputCountryCode = event => {
        setCountryCode(event.target.value);
    };

    /**
     * 获取验证码
     */
    const onClickGetSmsCode = ({ isFirstTime }) => {
        const fullTel = `${countryCode ? countryCode : '+86'}${tel}`;
        if (!tel || tel.length === 0) {
            return showToast('请输入手机号');
        }
        showLoading();

        Misc.getSmsCode({ mobile_number: fullTel })
            .then(() => {
                hideLoading();
                clearInterval(timer.current);
                setRetrySmsTime(SMS_INTERVAL);
                timerRef.current = SMS_INTERVAL;
                initSmsTimer();
                if (isFirstTime) {
                    setHasSendSmsCode(true);
                }
            })
            .catch(err => showToast(err.msg));
    };

    const SmsBtn = () => {
        if (!hasSendSmsCode) {
            return (
                <div
                    className={styles.code}
                    onClick={() =>
                        onClickGetSmsCode({
                            isFirstTime: true,
                        })
                    }
                >
                    获取验证码
                </div>
            );
        } else {
            return retrySmsTime !== 0 ? (
                <div className={styles.code}>{retrySmsTime}s</div>
            ) : (
                <div className={styles.code} onClick={onClickGetSmsCode}>
                    重新发送
                </div>
            );
        }
    };

    const isPropertyOrList = () => {
        return (
            query.from === '/property' ||
            (query.from.indexOf('/list') > -1 && query.from.indexOf('detail') === -1)
        );
    };

    const isShowBackIcon = () => {
        return isPropertyOrList();
    };

    const onClickBack = () => {
        history.replace(query.from || '/property');
    };

    return (
        <>
            <BasicWrapper>
                <Header
                    onClickBack={onClickBack}
                    needBackToHome={true}
                    needBack={isShowBackIcon}
                    title="用户登录"
                ></Header>
                <div className={styles['login-wrap']}>
                    <div className={styles['tips']} style={{ whiteSpace: 'pre-wrap' }}>
                        {'您好，\n欢迎来到鹿可财富'}
                    </div>

                    <div className={styles['login-box']}>
                        <div className={styles.line}>
                            <div className={styles.left}>
                                <Input
                                    onChange={onInputCountryCode}
                                    value={countryCode}
                                    bordered={false}
                                />
                            </div>

                            <div className={styles.right}>
                                <Input
                                    type="number"
                                    onChange={onChangeTel}
                                    value={tel}
                                    bordered={false}
                                    placeholder="请填写手机号"
                                />
                            </div>
                        </div>
                        <div className={styles.line}>
                            <div className={styles.left} style={{ borderBottom: 'none' }}>
                                验证码
                            </div>
                            <div className={styles.right} style={{ borderBottom: 'none' }}>
                                <Input
                                    type="number"
                                    onChange={onChangeSmsCode}
                                    value={smsCode}
                                    maxLength={6}
                                    bordered={false}
                                    placeholder="请输入验证码"
                                />

                                <SmsBtn />
                            </div>
                        </div>
                    </div>

                    <div className={styles['btn-wrap']}>
                        <button
                            className={`${styles['next-step-btn']} ${
                                !!tel && !!smsCode ? '' : styles['next-step-btn-disable']
                            }`}
                            onClick={onClickNextStep}
                        >
                            <div>登录或注册</div>
                        </button>
                    </div>
                </div>
            </BasicWrapper>
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setLogin: newLoginData => dispatch(setLogin(newLoginData)),
        setHasToken: hasToken => dispatch(setHasToken(hasToken)),
    };
};

export default connect(state => ({ lang: state.config.lang }), mapDispatchToProps)(Login);
