import * as ActionType from './../../../constants/constants';
const defaultState = {
    mobileType: 1024,
};

function windowSizeReducer(state = defaultState, action) {
    switch (action.type) {
        case ActionType.WIDTH1024:
            return { ...state, mobileType: 1024 };
        case ActionType.WIDTH1440:
            return { ...state, mobileType: 1440 };
        default:
            return state;
            break;
    }
}

export default windowSizeReducer;
