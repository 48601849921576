// import useRedirectWhenTokenDoesntExisted from '../../hooks/useRedirectWhenTokenDoesntExisted';
import useSetPageBasicTrack from '../../hooks/useSetPageBasicTrack';
import styles from './BasicWrapper.module.scss';
import PortalTabbar from '../PortalTabbar/PortalTabbar';

const BasicWrapper = ({ children, style, needTabBar = false }) => {
    // const { redirectPath } = useRedirectWhenTokenDoesntExisted();

    // let needRender = redirectPath ? false : true;

    /**
     * 开始打页面基础埋点（页面停留时长，页面曝光等）
     */
    useSetPageBasicTrack(true);

    return (
        <div className={styles['basic-wrapper']} style={style}>
            <div className={needTabBar ? styles['content-wrapper'] : ''}>{children}</div>

            {!!needTabBar && <PortalTabbar />}
        </div>
    );
};

export default BasicWrapper;
