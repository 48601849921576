import React, { useState, useMemo, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import HomeTitle from '../HomeTitle-mobile';
import { useTranslation } from 'react-i18next';
import map from '../../../../images/homeMap.png';
import MobileHeader from '../mobile-header'
import './index.scss'

export default function Aum(props) {
    const { t, i18n } = useTranslation();
    return <div className='aum-contain-mobile' id="aum">
        <HomeTitle title={t('aum-title')}></HomeTitle>
        <div className='aum-content'>
            <div className='aum-content-header'>
                <MobileHeader content={t('aum-des1')} />
            </div>
            <div className='aum-contain-mobile-box'>
                <div className='aum-content-des2'>{t('aum-des2')}</div>
                <div className='aum-content-des3'>{t('aum-des3')}</div>
                <div className='aum-content-des4'>{t('aum-des4')}</div>
            </div>
        </div>

    </div>
}