import HomeTitle from '../HomeTitle-mobile';
import style from './index.module.scss';
import map from '../../../../images/homeMap-mobile.png';
import mapEn from '../../../../images/homeMap-mobileEn.png';
import logo1 from '../../../../images/about_logo1.png';
import logo2 from '../../../../images/about_logo2.png';

import { useTranslation } from 'react-i18next';
const About = () => {
    const { t, i18n } = useTranslation();
    const isEN = i18n.language === 'en';
    const isZH = i18n.language === 'zh';

    return (
        <div className={`${style.container} ${i18n.language === 'en' ? style.containerEn : ''}`}>
            <span className="nav-anchor" id="about"></span>
            <HomeTitle title={t('about-mobile-title')}></HomeTitle>
            <div className={style.content}>
                <div className={style.tip}>{t('about-desc')}</div>
                {isEN && <div className={style.tip2}>{t('about-desc2')}</div>}
                {isZH && <div className={style.top}>{t('about-slogan')}</div>}
                {isZH && <div className={style.bottom}>{t('about-slogan-desc')}</div>}
                <img src={i18n.language === 'zh' ? map : mapEn} alt="" className={style.mapimg} />
                {isZH && <div className={style.imgtop}>{t('about-location')}</div>}
                {isZH && (
                    <div className={style.imgbottom}>
                        UBS / J.P.Morgan / Lombard Odier / Bank of Singapore / Standard Chartered
                    </div>
                )}
                {isEN && (
                    <div className={style.slogan}>
                        <img src={logo1} alt="" />
                        <div>
                            <span className={style.slogan_title}>{t('about-slogan')}</span>
                            <br />
                            <span className={style.slogan_desc}>{t('about-slogan-desc')}</span>
                        </div>
                    </div>
                )}
                {isEN && (
                    <div className={style.slogan}>
                        <img src={logo2} alt="" />
                        <div>
                            <span className={style.slogan_title}>{t('about-location')}</span>
                            <br />
                            <span className={style.slogan_desc}>{t('about-location-desc')}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default About;
