import React, { useState, useMemo, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import HomeTitle from '../HomeTitle-mobile';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MobileHeader from '../mobile-header';

import bg1 from '../../../../images/tech-bg1.png';

import bg3 from '../../../../images/tecImg1-mobileen.png';
import bg4 from '../../../../images/tecImg2-mobileen.png';
import bg2 from '../../../../images/tecImg0-mobileen.png';
import './index.scss';

export default function Tech(props) {
    const { t, i18n } = useTranslation();
    const is1440 = useSelector(state => state.windowSizeReducer.mobileType === 1440);

    const [cur, setCur] = useState(0);
    const [showImg, setShow] = useState(true);
    const curTab = useMemo(() => {
        const tabs = [
            {
                backgroundImg: bg1,
                descriptions: [
                    {
                        num: '700+',
                        text: t('technology-static-desc1'),
                    },
                    {
                        num: '50+',
                        text: t('technology-mobile-static-desc2'),
                    },
                    {
                        num: '5000+',
                        text: t('technology-static-desc3'),
                    },
                ],
            },
            {
                backgroundImg: bg2,
            },
            {
                backgroundImg: bg3,
            },
            {
                backgroundImg: bg4,
            },
        ];
        return tabs[cur];
    }, [i18n.language, cur]);

    const selectBars = useMemo(
        () => [
            t('technology-select-bar1'),
            t('technology-mobile-select-bar2'),
            t('technology-select-mobie-bar3'),
            t('technology-select-bar4'),
        ],
        [i18n.languag],
    );

    const clickBar = function (index) {
        setShow(false);
        setCur(index);
        setTimeout(() => {
            setShow(true);
        }, 100);
    };

    return (
        <div className="tech-contain-mobile" id="tech">
            <HomeTitle title={t('technology-title')}></HomeTitle>
            <div className="tech-mobile-box">
                <MobileHeader content={t('technology-subTitle')} />

                <div className="tech-content">
                    {showImg && (
                        <img
                            src={curTab?.backgroundImg}
                            alt=""
                            className={`tech-content-bg ${cur === 0 && 'tech-fullfill'}`}
                        />
                    )}
                    {curTab?.descriptions && (
                        <div className="tech-static-box">
                            {curTab?.descriptions?.map((item, index) => (
                                <div className="tech-static-item" key={index}>
                                    <div className="tech-static-item-num">{item.num}</div>
                                    <div className="tech-static-item-desc">{item.text}</div>
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="tech-selectBar">
                        {selectBars?.map((item, index) => (
                            <div
                                className={`tech-selectBar-btn ${
                                    index === cur && 'tech-selectBar-btn-active'
                                }`}
                                onMouseMove={() => {
                                    clickBar(index);
                                }}
                                key={index}
                            >
                                {item}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
