import { createRef, useCallback, useEffect, useRef, useState } from 'react';
import styles from './DashbordChart.module.scss';
import { useHistory } from 'react-router';
import { showFail, showToast } from '../../../utils/toast';
import * as echarts from 'echarts';
import asset from '../../../services/asset';
import {initOption} from './utils'

const DashboardChart = () => {
    const dashboardContentRef = useRef(null);
    const dashboardRef = useRef(null);

    useEffect(() => {
        asset
            .getAssetFactor({
                account_id: '_total',
            })
            .then(res => {
                dashboardRef.current.setOption(initOption(res));
            })
            .catch(err => {
                console.error(err);
                showToast(err.msg);
            });
    }, []);
    useEffect(() => {
        if (dashboardContentRef.current) {
            dashboardRef.current = echarts.init(dashboardContentRef.current);
        }
    }, [dashboardContentRef.current]);
    return (
        <div className={styles['dashbord-wrapper']}>
            <div className={styles.title}>风险评级</div>
            <div className={styles.container} ref={dashboardContentRef}></div>
            {/* <div className={styles.warning}>您账户当前为高风险状态，请分散投资</div> */}
        </div>
    );
};

export default DashboardChart;
