import axios from '../utils/o-axios';
const isMoblie = () => {
    return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
    );
};
class Misc {
    getSmsCode = data => {
        return axios.post('/v1/misc/send_cc', {
            data,
        });
    };

    eventReport = parmas => {
        let data = parmas;
        if (Array.isArray(data)) {
            data = parmas.map(item => ({ ...item, from: isMoblie() ? 'h5' : 'pc' }));
        }
        return axios.post('/v2/misc/event_report', {
            data,
        });
    };
}

export default new Misc();
