import axios from '../utils/quote-axios';

class Quote {
    getProductDetails = data => {
        return axios.post('/v1/product/detail_for_disp', {
            data,
        });
    };
}

export default new Quote();
