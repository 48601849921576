import style from './index.module.scss';
import minibaike from '../../../../images/mini-baike.png';
import moreicon from '../../../../images/more.png';
import home from '../../../../services/home';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import misc from '../../../../services/misc';
const Recommend = () => {
    const { t, i18n } = useTranslation();

    const [data, setData] = useState([]);
    useEffect(() => {
        home.getArticle().then(res => {
            if (res.code === 0 && res.data !== undefined) {
                setData(res.data);
            }
        });
    }, []);

    return (
        <div className={`${style.content} hide-scrollbar`}>
            {data.map(item => (
                <div
                    key={item.id}
                    className={style.item}
                    onClick={() => {
                        misc.eventReport([
                            {
                                event_type: 2,
                                event_id: 30023,
                                params: { title: item.doc_title },
                                ts: +new Date(),
                                sid: '',
                            },
                        ]);
                        window.open(item.redirectUrl, '_blank');
                    }}
                >
                    <div
                        className={style.topBg}
                        style={{ backgroundImage: `url(${item.topicTitleImg})` }}
                    >
                        <div className={style.topicIcon}>
                            <div className={style.topicIconTitle}>{item.name}</div>
                            <img src={minibaike} alt="" className={style.topicIconIcon} />
                        </div>
                    </div>

                    <div className={style.textContent}>
                        <div className={style.title}>{item.doc_title}</div>
                        <div className={style.body}>{item.summary}</div>
                        <div
                            className={`${style.more} ${
                                i18n.language === 'en' ? style.moreEn : ''
                            }`}
                        >
                            {t('view-more')}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
export default Recommend;
