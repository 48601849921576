import advantageIcon1 from '../../../../images/advantageIcon1.png';
import advantageIcon3 from '../../../../images/advantageIcon3.png';
import advantageIcon2 from '../../../../images/advantageIcon2.png';
import advantageLine from '../../../../images/advantage-line.png';
import HomeTitle from '../HomeTitle';
import { useTranslation } from 'react-i18next';
import style from './index.module.scss';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const Advantage = () => {
    const { t, i18n } = useTranslation();
    const is1024 = useSelector(state => state.windowSizeReducer.mobileType === 1024);
    const advantageData = useMemo(
        () => [
            {
                title: t('advantage-item-title1'),
                icon: advantageIcon1,
                descTitle: t('advantage-item-descTitle1'),
                descContent: t('advantage-item-descContent1'),
            },
            {
                title: t('advantage-item-title2'),
                icon: advantageIcon2,
                descTitle: t('advantage-item-descTitle2'),
                descContent: t('advantage-item-descContent2'),
            },
            {
                title: t('advantage-item-title3'),
                icon: advantageIcon3,
                descTitle: t('advantage-item-descTitle3'),
                descContent: t('advantage-item-descContent3'),
            },
        ],
        [t],
    );
    return (
        <>
            <HomeTitle title={t('advantage-title')}></HomeTitle>
            <div className={`${style.advantage} ${is1024 ? style.advantage1024 : ''}`}>
                {advantageData.map((item, index) => {
                    return (
                        <div className={style.advantageItem} key={index}>
                            <img src={item.icon} alt="" className={style.img} />
                            <div
                                className={`${style.bottomContent} ${
                                    i18n.language === 'en' ? style['bottomContent-en'] : ''
                                }`}
                            >
                                <div className={style.bottomtitle}>
                                    <span>{`0${index + 1}`}</span>
                                    <img src={advantageLine} alt="" className={style.line} />
                                    <span>{item.title}</span>
                                    <ul>
                                        <li>{item.descTitle}</li>
                                        <li>{item.descContent}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
export default Advantage;
