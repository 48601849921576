import { addChineseUnit } from '../../../portal_account/utils';
const a = {
    type: 'line_chart',
    title: '行情数据',
    data: {
        charts: [
            {
                title: '分时',
                x: {
                    title: '价格时间',
                    labels: [
                        '09:30',
                        '09:31',
                        '09:32',
                        '09:33',
                        '09:34',
                        '09:35',
                        '09:36',
                        '09:37',
                    ],
                    display_labels: ['09:30', '12:00', '16:00'],
                },
                y: [
                    {
                        field_titles: ['价格', '涨幅', '哈哈'],
                        values: [
                            [2.01, 1.2, 'abc'],
                            [5.01, 1.2, 'abc'],
                            [4.02, 1.21, 'def'],
                            [7.12, 1.31, 'ghi'],
                            [9.12, 1.31, 'ghi'],
                            [3.12, 1.31, 'ghi'],
                        ],
                        suffixes: ['', '%', ''],
                        max: 6.01,
                        min: 4.02,
                        base_line_value: 4.95,
                        show_right_label: 1,
                    },
                ],
            },
            {
                title: '五日图',
                x: {
                    title: '价格时间',
                    labels: ['10/19'],
                    display_labels: ['10/19'],
                },
                y: [
                    {
                        field_titles: ['价格', '涨幅', '哈哈'],
                        values: [
                            [5.01, 1.2, 'abc'],
                            [5.02, 1.21, 'def'],
                            [5.12, 1.31, 'ghi'],
                        ],
                        suffixes: ['', '%', ''],
                        max: 6.01,
                        min: 4.02,
                        base_line_value: 4.95,
                        show_right_label: 1,
                    },
                ],
            },
        ],
    },
};
export function initOption(res, selectedIndex = 0, formatter) {
    const step =
        (Number(res.data.charts[selectedIndex].y[0].max) -
            Number(res.data.charts[selectedIndex].y[0].min)) /
        7;
    const min = Number(res.data.charts[selectedIndex].y[0].min) - step;
    const max = Number(res.data.charts[selectedIndex].y[0].max) + step;
    const baseValue = res.data.charts[selectedIndex].y[0].base_line_value[0];
    return {
        xAxis: {
            type: 'category',
            data: res.data.charts[selectedIndex].x.labels,
            axisTick: {
                show: false,
            },
            axisLabel: {
                interval: 0,
                showMinLabel: true,
                showMaxLabel: true,
                fontSize: 11,
                formatter: (value, index) => {
                    if (
                        res.data.charts[selectedIndex].x.display_labels.findIndex(
                            item => item === value,
                        ) > -1
                    ) {
                        if (index === 0) {
                            return `        ${value}`;
                        } else if (index === res.data.charts[selectedIndex].x.labels.length - 1) {
                            return `${value}       `;
                        } else {
                            return value;
                        }
                    }
                    return null;
                },
            },
        },
        yAxis: [
            {
                min,
                max,
                type: 'value',
                splitLine: {
                    lineStyle: {
                        color: 'rgba(255, 255, 255, 0.1)',
                    },
                },
                interval: (max - min) / 4,
                axisLabel: {
                    verticalAlign: 'bottom',
                    margin: 0,
                    inside: true,
                    fontSize: 11,
                    color: num => {
                        if (num > baseValue) {
                            return '#FF0000';
                        } else {
                            return '#4CD964';
                        }
                    },
                    formatter: value => {
                        return value.toFixed(3);
                    },
                },
            },
            {
                min,
                max,
                type: 'value',
                splitLine: 'none',
                interval: (max - min) / 4,
                axisLabel: {
                    verticalAlign: 'bottom',
                    margin: 0,
                    fontSize: 11,
                    inside: true,
                    color: num => {
                        if (num > baseValue) {
                            return '#FF0000';
                        } else {
                            return '#4CD964';
                        }
                    },
                    formatter: value => {
                        const result = ((value - baseValue) / baseValue).toFixed(2);
                        if (result > 0) {
                            return '+' + result + '%';
                        }
                        return result + '%';
                    },
                },
            },
        ],
        tooltip: {
            formatter,
            trigger: 'axis',
            showDelay: 0, //显示延时，添加显示延时可以避免频繁切换
            hideDelay: 500, //隐藏延时
            transitionDuration: 0, //动画变换时长
            axisPointer: {
                axis: 'x',
                type: 'line',
                lineStyle: {
                    color: '#FFF',
                    width: 1,
                    type: 'solid',
                    selfDefine: 'self defined function to help draw tip panel', //modify, new add
                },
            },
        },
        axisPointer: {
            label: {
                show: false,
            },
            z: 0,
        },
        grid: {
            left: 0,
            right: 0,
            top: 20,
            bottom: 30,
        },
        series: res.data.charts[selectedIndex].y.map((laneItem, selectedIndex) => ({
            data: laneItem.values.map(value => value[0]),
            type: 'line',
            symbol: 'circle',
            showSymbol: false,
            // 鼠标经过折线时的圆点大小
            symbolSize: 6,
            legendHoverLink: false,
            hoverAnimation: false,
            emphasis: {
                scale: false,
                lineStyle: {
                    color: '#FFC200',
                    width: 1,
                },
            },
            itemStyle: {
                borderColor: '#FFF',
                color: '#FFC200',
            },
            lineStyle: {
                width: 1,
                color: '#FFC200',
            },
        })),
    };
}
