import React, { useState, useMemo, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import HomeTitle from '../HomeTitle';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import waveImg from '../../../../images/header-wave.png'

import './index.scss'


export default function Aum({ content }) {
    const { t, i18n } = useTranslation();

    return <div className="mobile-header-container">
        <img src={waveImg} alt="" className='mobile-header-img' />
        {content}
    </div>
}