import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

/**
 * 设置lastPathname
 */
const useSetLastPathname = () => {
    const location = useLocation();
    const lastLocationPathnameRef = useRef(location.pathname);

    /** 将lastPathname设置为上一次的pathname */
    setLastPathname(lastLocationPathnameRef.current);

    /**
     * 设置lastPathname
     * @param {*} path 上一个路由的pathname
     * @returns
     */
    function setLastPathname(path = '') {
        //TODO 如果用户输入一个/fdkfdkf的页面，会无法导向任何一只基金
        /** 如果为/login，则看看缓存有没有，如果没有给一个默认值/fund_detail_dt，兜底 */
        let lastPathname =
            path === '/login' ? localStorage.getItem('lastPathname') || '/property' : path;

        /**
         * 如果为portal_product_preview_detail，预览页，login后需要跳到portal_product_detail
         */
        // if (path.indexOf('portal_product_preview_detail') !== -1) {
        //     lastPathname = path.replace('portal_product_preview_detail', 'portal_product_detail');
        // }

        /**
         * 针对/login页刷新情况做过滤
         */
        if (path === '/login' && localStorage.getItem('lastPathname') !== null) {
            return;
        } else {
            localStorage.setItem('lastPathname', lastPathname);
        }
    }

    useEffect(() => {
        /** 保存上一个页面的pathname */
        lastLocationPathnameRef.current = location.pathname;
    }, [location.pathname]);
};

export default useSetLastPathname;
