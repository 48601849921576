import style from './index.module.scss';
import { useState } from 'react';
import HomeTitle from '../HomeTitle';
import bgImg from '../../../../images/bank-bg.png';
import bgEnImg from '../../../../images/bank_enbg.png';
import arrow from '../../../../images/bank-arrow.png';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
export const urlArray = [
    'https://www.ubs.com/sg/sc.html',
    'https://privatebank.jpmorgan.com/gl/en/home',
    'https://www.sc.com/en/banking/banking-for-individuals/private-banking/',
    'https://www.bankofsingapore.com/zh-cn/Homepage.html',
    'https://privatewealth.goldmansachs.com/us/en/apac',
    'https://www.juliusbaer.com/',
    'https://www.lgtwm.com',
    'https://wealthmanagement.bnpparibas/asia/en.html',
    'https://www.uobkayhian.com/',
    'https://www.interactivebrokers.com/',
];
const bankName = [
    '瑞士银行',
    '摩根大通银行',
    '渣打银行',
    '新加坡银行',
    '高盛',
    '瑞士宝盛',
    'LGT皇家银行',
    '法国巴黎银行',
    '大华继显',
    '盈透证券',
];
const englishBankName = [
    'UBS',
    'J.P.Morgan',
    'Standard Chartered',
    'Bank of Singapore',
    'Goldman Sachs',
    'Julius Bär',
    'LGT',
    'BNP',
    'UOB-Kay Hian',
    'IBKR',
];
const bankDesc = [
    '全球最大的财富管理机构',
    '美国顶级金融服务机构',
    '英国权威的国际银行',
    '亚洲领先的环球私人银行',
    '美国头部投资金融机构',
    '瑞士百年精品私人银行',
    '服务全欧洲最富有的列支敦士登王室家族',
    '法国体量最大银行',
    '新加坡大华银行旗下亚洲最大券商之一',
    '全球最大在线券商之一',
];
const BrandLogo = props => {
    const { value } = props;
    const { i18n } = useTranslation();
    const is1024 = useSelector(state => state.windowSizeReducer.mobileType === 1024);

    let image = require(`../../../../images/cooperation_bank_0${value + 1}.png`);
    const [hovered, setHovered] = useState(false);

    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                // backgroundColor: 'white',
            }}
            key={value}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <a style={{ height: '100%', width: '100%', display: 'block', borderRadius: '16px' }}>
                <img
                    src={image.default}
                    style={{
                        opacity: hovered ? 0 : 1,
                    }}
                    className={`${is1024 ? 'bankDefaultImg1024' : 'bankDefaultImg'}`}
                />
                {i18n.language === 'zh' ? (
                    <div
                        style={{
                            position: 'absolute',
                            height: '100%',
                            opacity: hovered ? 1 : 0,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            padding: is1024 ? '0 16px' : '0 20px',
                            width: '100%',
                            left: 0,
                            borderRadius: '16px',
                            backgroundColor: '#FFD400',
                            transition: 'opacity 0.2s ease-in-out',
                        }}
                        className={`bank-hover-content`}
                        onClick={() => {
                            window.open(urlArray[value]);
                        }}
                    >
                        <div className={`bank-hover-name${is1024 ? '-1024' : ''}`}>
                            {`${bankName[value]}${value === 0 ? '*' : ''}`}
                        </div>
                        <div className={`bank-hover-body${is1024 ? '-1024' : ''}`}>
                            {bankDesc[value]}
                        </div>
                    </div>
                ) : (
                    <div
                        style={{
                            position: 'absolute',
                            height: '100%',
                            opacity: hovered ? 1 : 0,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            left: 0,
                            borderRadius: '16px',
                            backgroundColor: '#FFD400',
                            transition: 'opacity 0.2s ease-in-out',
                        }}
                        onClick={() => {
                            window.open(urlArray[value]);
                        }}
                    >
                        <div className="bank-hover-name-en">{`${englishBankName[value]}${
                            value === 0 ? '' : ''
                        }`}</div>
                        <img src={arrow} alt="" className="bank-hover-name-arrow" />
                    </div>
                )}
            </a>
        </div>
    );
};

const Bank = () => {
    const { t, i18n } = useTranslation();
    const is1024 = useSelector(state => state.windowSizeReducer.mobileType === 1024);
    const isEn = i18n.language === 'en';

    return (
        <div className={`${style.container} ${is1024 ? style.container1024 : ''}`} id="partners">
            <img src={isEn ? bgEnImg : bgImg} alt="" className={style.bgimg} />
            <div className={style.contentTop}>
                <HomeTitle title={t('bank-title')}></HomeTitle>
                {isEn && <div className={style.subTitle}>{t('bank-subTitle')}</div>}
                <div className={style.content}>
                    {[...Array(10).keys()].map(value => (
                        <div className={style.bank} key={value}>
                            <BrandLogo value={value} />
                        </div>
                    ))}
                </div>
                <div className={style.desc}>{t('bank-desc')}</div>
            </div>
        </div>
    );
};
export default Bank;
