import { useEffect } from 'react';
import { useLocation } from 'react-router';

const useBackToTop = () => {
    const location = useLocation();

    useEffect(() => {
        /**
         * back to top when routes are changed
         */
        if (document) {
            if (document?.documentElement || document?.body) {
                document.documentElement.scrollTop = document.body.scrollTop = 0;
            }
        }
    }, [location.pathname]);
};

export default useBackToTop;
