import advantageIcon1 from '../../../../images/advantageIcon1.png';
import advantageIcon3 from '../../../../images/advantageIcon3.png';
import advantageIcon2 from '../../../../images/advantageIcon2.png';
import advantageLine from '../../../../images/advantage-line-mobile.png';
import advantageMask from '../../../../images/advantage-mask.png';
import HomeTitle from '../HomeTitle-mobile';
import style from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
const Advantage = () => {
    const { t, i18n } = useTranslation();
    const advantageData = useMemo(
        () => [
            {
                title: t('advantage-item-title1'),
                icon: advantageIcon1,
                descTitle: t('advantage-item-descTitle1'),
                descContent: t('advantage-item-descContent1'),
            },
            {
                title: t('advantage-item-title2'),
                icon: advantageIcon2,
                descTitle: t('advantage-item-descTitle2'),
                descContent: t('advantage-item-descContent2'),
            },
            {
                title: t('advantage-item-title3'),
                icon: advantageIcon3,
                descTitle: t('advantage-item-descTitle3'),
                descContent: t('advantage-item-descContent3'),
            },
        ],
        [t],
    );
    return (
        <div className={i18n.language === 'en' ? style.bodyEn : style.body}>
            <HomeTitle title={t('advantage-title')}></HomeTitle>
            <div className={`${style.advantage} hide-scrollbar`}>
                {advantageData.map((item, index) => {
                    return (
                        <div className={style.advantageItem} key={index}>
                            <div
                                className={style.img}
                                style={{ backgroundImage: `url(${item.icon})` }}
                            >
                                <img src={advantageMask} alt="" className={style.mask} />
                                <div className={style.content}>
                                    <span>{`0${index + 1}`}</span>
                                    <img src={advantageLine} alt="" className={style.line} />
                                    <span>{item.title}</span>
                                </div>
                            </div>

                            <div className={style.bottomContent}>
                                <div className={style.bottomtitle}>
                                    <ul>
                                        <li>{item.descTitle}</li>
                                        <li>{item.descContent}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export default Advantage;
