const detectDeviceType = () =>
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        ? 'H5'
        : 'PC';

const host = {
    dev: 'https://portal-test.ilookcap.com/api',
    stage: 'https://portal-stage.ilookcap.com/api',
    prod: 'https://portal.ilookcap.com/api',
};
const baikeHost = {
    dev: 'https://baike-test.ilookcap.com/api',
    stage: 'https://baike-stage.ilookcap.com/api',
    prod: 'https://baike.ilookcap.com/api',
};

export const setting = {
    host: host[process.env.REACT_APP_ENV],
    /** "int, 客户端类型, 1-小程序, 2-H5，3-App, 5-web", */
    client_type: detectDeviceType() === 'H5' ? 2 : 5,
    baikeHost: baikeHost[process.env.REACT_APP_ENV],
    /** token过期时间 */
    // 永久
    ttl: 1000 * 60 * 24 * 24 * 24 * 1000 * 9999999999999,
};
const quote_host = {
    dev: 'https://quote-test.ilookcap.com/api',
    stage: 'https://quote-stage.ilookcap.com/api',
    prod: 'https://quote.ilookcap.com/api',
};
export const quoteSetting = {
    host: quote_host[process.env.REACT_APP_ENV],
    /** "int, 客户端类型, 1-小程序, 2-H5，3-App, 5-web", */
    client_type: detectDeviceType() === 'H5' ? 2 : 5,
    /** token过期时间 */
    // 永久
    ttl: 1000 * 60 * 24 * 24 * 24 * 1000 * 9999999999999,
};

export const constantsText = {
    error: '出错了',
};
