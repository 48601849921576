import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import track from '../utils/track';

/** 打点间隔 */
const STAY_TIME_INTERVAL = 10000;

/** 计时器间隔 */
const TIMEOUT = 500;

/**
 * 打页面基础埋点（页面停留时长，页面曝光等）
 */
const useSetPageBasicTrack = (needRender = true, fromHome = false) => {
    const location = useLocation();

    /** 毫秒级别 */
    const stayTime = useRef(0);

    /** stayTime计时器 */
    const timer = useRef(0);

    /** 10秒一打点计时器 */
    const stayManagerTimer = useRef(null);

    useEffect(() => {
        /**
         * 需要页面渲染的时候，再打点
         */
        if (needRender) {
            /** 设置pathname */
            const home = fromHome ? '/home' : '';
            const pathname = `${home}${location?.pathname}` || ''
            track.setPathname(pathname);
        }
    }, [location.pathname, needRender]);

    useEffect(() => {
        const onPageExpose = () => {
            track.send({ tp: '1', dc: '10001', message: '页面曝光' });
        };

        const startTimer = () => {
            timer.current = setInterval(() => {
                stayTime.current += TIMEOUT;
            }, TIMEOUT);
        };

        const __sendTrack = () => {
            track.send({
                tp: '1',
                dc: '10002',
                message: '页面停留时长',
                data: {
                    stayTime: stayTime.current,
                },
            });
        };

        /** 页面加载 */
        const __onPageMounted = () => {
            /** 页面曝光打点 */
            onPageExpose();

            /** 开始记时页面停留时长 */
            startTimer();

            /** 开启10秒一打点计时器 */
            stayManagerTimer.current = setInterval(() => {
                __sendTrack();
            }, STAY_TIME_INTERVAL);
        };

        /** 页面卸载 */
        const __onPageUnmounted = () => {
            /** 发送埋点 */
            __sendTrack();

            /** 清除stayTime计时器 */
            clearInterval(timer.current);

            /** 重置stayTime为0 */
            stayTime.current = 0;

            /** 清除掉10s打点计时器 */
            clearInterval(stayManagerTimer.current);
        };

        /**
         * 需要页面渲染的时候，再打点
         */
        if (needRender) {
            /**
             * 开始执行逻辑, 当页面挂载, 开始打点
             */
            __onPageMounted();

            return () => __onPageUnmounted();
        }
    }, [location.pathname, needRender]);
};

export default useSetPageBasicTrack;
