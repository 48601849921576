import { useHistory, useLocation } from 'react-router';
import styles from './FeedbackBtn.module.scss';
import track from '../../utils/track';

export default ({ children, style }) => {
    const history = useHistory();
    const location = useLocation();

    const onClickFeedbackBtn = () => {
        track.send({
            tp: '2',
            dc: '10002',
            message: `意见反馈按钮点击`,
        });
        history.replace(`/feedback?from=${location.pathname}`);
    };

    return (
        <div className={styles.container} style={style}>
            <div className={styles.text} onClick={onClickFeedbackBtn}>
                意见反馈
            </div>
        </div>
    );
};
