import styles from './index.module.scss';
import advantageLineColor from '../../../../images/advantage-line-color.png';
import { useTranslation } from 'react-i18next';


const HomeTitle = ({ title, titleColor }) => {
    const { t, i18n } = useTranslation();
    const isEN = i18n.language === 'en'

    return (
        <>
            <div className={styles.title}>
                <img src={advantageLineColor} alt="" className={styles.lkAdvantageLine} />
            </div>
            <div className={`${styles.title} ${isEN && styles.titleEn}`} style={{ color: titleColor }}>
                {title}
            </div>
        </>
    );
};
export default HomeTitle;
