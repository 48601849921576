import s from './index.module.scss';
import companyIcon from '../../../../images/contact-company.png';
import advantageLineColor from '../../../../images/advantage-line-color.png';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Company = () => {
    const { t } = useTranslation();
    const is1024 = useSelector(state => state.windowSizeReducer.mobileType === 1024);

    return (
        <div className={`${s.body} ${is1024 ? s.body1024 : ''}`}>
            <div className={s.container}>
                <div className={s.left}>
                    <img src={advantageLineColor} alt="" className={s.icon} />
                    <div className={s.title}>{t('company-title')}</div>
                    <div className={s.subtitle}>{t('company-desc')}</div>
                </div>
                <img src={companyIcon} alt="" className={s.right} />
            </div>
        </div>
    );
};
export default Company;
