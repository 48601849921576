import styles from './CardWithTable.module.scss';
import { useHistory, useLocation } from 'react-router';
import vector from '../../../../images/vector.png';
export default ({ data }) => {

    return (
        <div className={styles.wrap}>
            <div className={styles.title}>{data.title}</div>
            <div className={styles.login_wrapper}></div>
            <div className={styles.list}>
                {data.data.map((v, index) => (
                    <div className={styles.item} key={index}>
                        <div className={styles.left}>{v.title}</div>
                        <div className={styles.right}>{v.value}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};
