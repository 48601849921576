import axios from '../utils/o-axios';

class User {
    login = data => {
        return axios.post('/v1/user/gid', {
            data,
        });
    };

    getUserInfo = data => {
        return axios.post('/v1/user/info', {
            data,
        });
    };

    getUserStatus = data => {
        return axios.post('/v1/user/status', {
            data,
        });
    };
}

export default new User();
