import axios from '../utils/baike-axios';
class Home {
    getArticle = () => {
        return axios.post('/v1/wxmp/topic_operation/list', {
            data: { from: 3 },
        });
    };
}

export default new Home();
