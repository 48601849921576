import axios from '../utils/o-axios';

class Asset {
    getAssetCate = data => {
        return axios.post('/v1/asset/cate', {
            data,
        });
    };

    getAssetList = data => {
        return axios.post('/v1/asset/summary', {
            data,
        });
    };

    getAssetSummary = data => {
        return axios.post('/v1/asset/summary', {
            data,
        });
    };

    getAssetSummaryHistoryChart = data => {
        return axios.post('/v1/asset/summary_history_chart', {
            data
        });
    };
    getAssetFactor = data => {
        return axios.post('v1/asset/factor', {
            data
        });
    };
}

export default new Asset();
