import styles from './NetAssetCard.module.scss';
import { createRef, useCallback, useEffect, useState, forwardRef } from 'react';
import wxJsSdk from '../../../../utils/wxJsSdk';
import Asset from '../../../../services/asset';
import Look_Cap from '../../../../assets/images/lookcap.png';
import { showFail, showToast } from '../../../../utils/toast';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

// 字符串第一位为负号返回绿的，否则返回红色
const getColorOfFluctuation = fluctuString =>
    fluctuString && fluctuString[0] === '-' ? '#4CD964' : '#FF0000';
const formatDate = timeStamp => {
    const date = new Date(timeStamp);
    const YY = date.getFullYear() + '-';
    const MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    const DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    const mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    const ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return YY + MM + DD + ' ' + hh + mm + ss;
};

const NetAssetCard = forwardRef((_, ref) => {
    const [isReady, setIsReady] = useState(false);
    const [summary, setSummary] = useState(null);
    const history = useHistory();
    let hasToken = useSelector(state => state.token.hasToken);
    const onClick2Account = () => {
        history.replace(`/history`);
    };
    useEffect(() => {
        if (hasToken && !isReady) {
            Asset.getAssetSummary({})
                .then(res => {
                    console.log(res);
                    setSummary(res.data.asset);
                    setIsReady(true);
                    wxJsSdk.init({
                        title: '鹿可财富', // 分享标题
                        desc: '鹿可财富首页', // 分享描述
                        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: Look_Cap, // 分享图标});
                    });
                })
                .catch(err => {
                    console.error(err);
                    showToast(err.msg);
                });
        }
    }, [hasToken, isReady]);
    return (
        hasToken &&
        isReady &&
        summary && (
            <div className={styles.card_wrap} ref={ref} onClick={onClick2Account}>
                <div className={styles.title}>净资产 ({summary.currency})</div>
                <div className={styles['total-amount']}>
                    <span>{summary.net_asset}</span>
                    <span
                        className={styles.compare}
                        style={{ color: getColorOfFluctuation(summary.net_asset_diff) }}
                    >
                        (较昨日 {summary.net_asset_diff})
                    </span>
                </div>
                <svg
                    t="1628046120426"
                    className={styles.jump}
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1172"
                    width="200"
                    height="200"
                    fill="#9ca2aa"
                >
                    <path
                        d="M276.755 942.936c28.497 29.315 74.739 29.315 103.307 0l367.236-378.011c28.483-29.367 28.483-76.982 0-106.291l-367.236-377.997c-28.562-29.367-74.806-29.367-103.307 0-28.546 29.325-28.546 76.929 0 106.304l315.6 324.841-315.599 324.803c-28.545 29.367-28.544 76.973 0 106.356l0 0z"
                        p-id="1173"
                    ></path>
                </svg>
                <div className={styles['assets-and-liabilities']}>
                    <div className={styles.data}>
                        <div className={styles.type}>总资产</div>
                        <div className={styles.amount}>{summary.hold_value}</div>
                        <div
                            className={styles.wave}
                            style={{ color: getColorOfFluctuation(summary.hold_value_diff) }}
                        >
                            {summary.hold_value_diff}
                        </div>
                    </div>
                    <div className={styles.data}>
                        <div className={styles.type}>总负债</div>
                        <div className={styles.amount}>{summary.debit_amount}</div>
                        <div
                            className={styles.wave}
                            style={{ color: getColorOfFluctuation(summary.debit_amount_diff) }}
                        >
                            {summary.debit_amount_diff}
                        </div>
                    </div>
                </div>
                <div className={styles['update-time']}>
                    更新时间: {formatDate(summary.updated_at)} 北京时间
                </div>
            </div>
        )
    );
});

export default NetAssetCard;
