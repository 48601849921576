import React, { useCallback } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { ValidatingRoutesList, NonValidatingRoutesList } from './routesConfig';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useSetLastPathname from '../hooks/useSetLastPathname';
import useBackToTop from '../hooks/useBackToTop';
import Official from '../pages/official/official';
const Routes = () => {
    const location = useLocation();
    let hasToken = useSelector(state => state.token.hasToken);

    useSetLastPathname();

    useBackToTop();

    return (
        <>
            <Switch location={location}>
                {NonValidatingRoutesList.map(route => (
                    <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                    ></Route>
                ))}
                <Route component={Official}></Route>
                {/* {hasToken &&
                    ValidatingRoutesList.map(route => (
                        <Route
                            key={route.path}
                            path={route.path}
                            exact={route.exact}
                            component={route.component}
                        ></Route>
                    ))} */}
                {/* <Redirect to={hasToken ? '/property' : '/login'} /> */}
            </Switch>
        </>
    );
};

export default Routes;
