import React, { useEffect, useState } from 'react';
import './downloadPC.scss';
import QRAnd from './images/qrcode-android-down.png';
import QRIOS from './images/qrcode_ios.png';
import CheckVersion from '../../services/download';
import header_title from '../../images/H1_header.png';
import header_title_en from '../../images/en-banner.png';
import header_title_en_mini from '../../images/en-1024-banner.png';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function DownloadPC() {
    const [updateTime, setUpdateTime] = useState('');
    const [updateVersion, setUpdateVersion] = useState('');
    const { t, i18n } = useTranslation();
    const is1024 = useSelector(state => state.windowSizeReducer.mobileType === 1024);
    useEffect(() => {
        CheckVersion.checkVersion().then(res => {
            setUpdateTime(res.data.data.release_date.split('-').join('.'));
            setUpdateVersion(res.data.data.new_version);
        });
    }, []);
    return (
        <div className={`download-container ${is1024 ? 'download-container-1024' : ''}`}>
            <div className="download-content-bg">
                <img
                    src={
                        i18n.language === 'zh'
                            ? header_title
                            : is1024
                            ? header_title_en_mini
                            : header_title_en
                    }
                    className="download-title"
                    style={i18n.language === 'zh' ? { marginTop: '200px' } : {}}
                />
                <div className="download-description">{t('banner-sub')}</div>
                <div className="row">
                    <div className="info-list">
                        <div>{`${t('version')}：${updateVersion}（${updateTime}${t(
                            'publish',
                        )}）`}</div>
                        <div>{`${t('developer')}：LOOK CAPITAL PTE. LTD`}</div>
                    </div>
                    <div className="sub-row">
                        <div className="right40">
                            <img className="border" src={QRAnd} alt="" />
                            <div className="name">Android</div>
                        </div>
                        <div>
                            <img className="border" src={QRIOS} alt="" />
                            <div className="name">iOS</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
