import { combineReducers } from 'redux';

import config from './module/config';
import login from './module/login';
import token from './module/token';
import windowSizeReducer from './../pages/official/store/reducer';
const rootReducer = combineReducers({
    config,
    login,
    token,
    windowSizeReducer,
});

export default rootReducer;
