import { useEffect, useState } from 'react';
import OfficialMobile from './OfficialMobile';
import OfficialPC from './OfficialPC';
import './official.scss';
import user from '../../services/user';
import cookie from 'react-cookies';
import misc from '../../services/misc';
import { useDispatch } from 'react-redux';
import * as ActionType from '../../constants/constants';
import { useTranslation } from 'react-i18next';
(function () {
    let running = false;
    let func = () => {
        if (running) return;
        running = true;
        requestAnimationFrame(() => {
            window.dispatchEvent(new CustomEvent('optimizedResize'));
            running = false;
        });
    };
    window.addEventListener('resize', func);
})();

export const ReqImg = url => {
    let image = require(`${url}`);
    return image.default;
};

export default function Official() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        document.title = t('document-title');
    }, [i18n.language]);

    const dispatch = useDispatch();
    const handleWindowResize = () => {
        setIsMobile(window.innerWidth < 1024);
        if (window.innerWidth >= 1440) {
            dispatch({ type: ActionType.WIDTH1440 });
        } else {
            dispatch({ type: ActionType.WIDTH1024 });
        }
    };

    useEffect(() => {
        window.addEventListener('optimizedResize', handleWindowResize);
        return () => window.removeEventListener('optimizedResize', handleWindowResize);
    }, []);
    useEffect(() => {
        if (window.innerWidth >= 1440) {
            dispatch({ type: ActionType.WIDTH1440 });
        } else {
            dispatch({ type: ActionType.WIDTH1024 });
        }
        if (cookie.load('login') === undefined) {
            let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000); //一天
            user.login().then(res => {
                cookie.save('login', JSON.stringify(res.data.login), { expires: inFifteenMinutes });
                eventPost();
            });
        } else {
            eventPost();
        }
    }, []);
    const eventPost = () =>
        misc.eventReport([
            {
                event_type: 1,
                event_id: '30003',
                ts: +new Date(),
                sid: '',
            },
        ]);

    return (
        <div style={{ backgroundColor: 'white', height: '100%' }} className="page-container">
            {isMobile ? <OfficialMobile /> : <OfficialPC />}
        </div>
    );
}
