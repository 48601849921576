import styles from './CardWithName.module.scss';

// type1
export default ({ data }) => {
    console.log(data);
    return (
        <div className={styles.head}>
            <div className={styles.title}>{data.title}</div>
            {data.data.length > 0 && (
                <div className={styles.foot}>
                    {data.data?.map((item, index) => (
                        <div className={styles.foot__item} key={index}>
                            <div className={styles.foot__item__num}>{item.value}</div>
                            <div className={styles.foot__item__label}>{item.title}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
