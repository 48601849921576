import axios from 'axios';
import { quoteSetting } from '../config';
import { v4 as uuid } from 'uuid';
import store from '../store/store';
import NProgress from 'nprogress';
import cookie from 'react-cookies';

const REPORT_URL = '/v2/misc/event_report';

NProgress.configure({ showSpinner: false });

function Axios() {
    const instance = axios.create({
        // timeout: 50000,
        baseURL: quoteSetting.host,
    });

    /** 所有接口都为POST,后续有get需要改配置 */
    const addBasicConfig = config => {
        const login = cookie.load('login') ?? {};
        const state = store.getState();
        /** add req_id & local data */
        config.data = {
            ...config.data,
            req_id: uuid().slice(0, 16),
            local: {
                client_type: quoteSetting.client_type,
                locale: state.config.lang,
            },
        };
        /** if has token */
        if (Object.keys(login).length > 0) {
            config.data = {
                ...config.data,
                login: login,
            };
        }
    };

    // backend interface docs
    instance.interceptors.request.use(
        config => {
            /** 打点不展示progress进度条 */
            if (config.url !== REPORT_URL) {
                NProgress.start();
            }

            /** add req_id & local data */
            addBasicConfig(config);
            return config;
        },
        err => {
            return Promise.reject(err);
        },
    );

    instance.interceptors.response.use(
        response => {
            if (response.config?.url !== REPORT_URL) {
                NProgress.done();
            }

            const code = response.data.code;
            /** 如果不存在，强制跳转login */
            if (code === -11 || code === -10 || code === -9) {
                localStorage.removeItem('login');
                window.location.pathname = 'login';

                return Promise.reject(response.data);
            }

            if (code !== 0) {
                return Promise.reject(response.data);
            } else {
                return response.data;
            }
        },
        error => {
            NProgress.done();
            return Promise.reject(error);
        },
    );

    return instance;
}

export default new Axios();
