import { createRef, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import styles from './portal_property.module.scss';
import Header from '../../layouts/header/header';
import BasicWrapper from '../../components/BasicWrapper/BasicWrapper';
import { useHistory, useLocation } from 'react-router';
import track from '../../utils/track';
import NetAssetCard from './components/NetAssetCard/NetAssetCard';
import Asset from '../../services/asset';
import { showFail, showToast } from '../../utils/toast';
import wxJsSdk from '../../utils/wxJsSdk';
import Look_Cap from '../../assets/images/lookcap.png';
import { useSelector } from 'react-redux';
import FeedbackBtn from '../../components/FeedbackBtn/FeedbackBtn';
import Logo from '../../images/littleLogo.png';
import { getHeight, group } from './util';
import Shares_card from './shares_card/shares_card';
import Cash_card from './cash_card/cash_card';

export default () => {
    const history = useHistory();
    const location = useLocation();
    let hasToken = useSelector(state => state.token.hasToken);

    const [secCates, setSecCates] = useState([]);
    const [scroll, setScroll] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(
        parseInt(localStorage.getItem('portalTabIndex') || 1),
    );
    const [isReady, setIsReady] = useState(false);
    const netAssetCardRef = useRef(null);
    const containerRef = useRef(null);
    const beforeScrollTop = useRef(0);
    const onClickSecCate = sub_sec_cate_id => {
        history.replace(`/list/${sub_sec_cate_id}`);
    };

    const changeTab = (index, name) => {
        track.send({
            tp: '2',
            dc: '01',
            message: `切换tab`,
            data: {
                tabName: name,
            },
        });
        localStorage.setItem('portalTabIndex', index);
        setCurrentIndex(index);
        if (
            netAssetCardRef.current &&
            containerRef.current &&
            containerRef.current.scrollTop < getHeight(netAssetCardRef.current)
        ) {
            setScroll(true);
        }
    };

    useEffect(() => {
        Asset.getAssetCate({})
            .then(res => {
                setSecCates(res.data.sec_cates);
                setIsReady(true);
                wxJsSdk.init({
                    title: '鹿可财富', // 分享标题
                    desc: '鹿可财富首页', // 分享描述
                    link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: Look_Cap, // 分享图标});
                });
            })
            .catch(err => {
                console.error(err);
                showToast(err.msg);
            });
    }, []);
    const onClickShadowLayer = () => {
        history.replace(`/login?from=${location.pathname}`);
    };
    useEffect(() => {
        if (containerRef.current) {
            beforeScrollTop.current = containerRef.current.scrollTop;
        }
    }, [isReady]);

    useEffect(() => {
        let stopAnimation = null;
        if (containerRef.current && containerRef.current && scroll) {
            const assectCardHeight = getHeight(netAssetCardRef.current);
            const step = () => {
                if (containerRef.current.scrollTop < 0) {
                    setScroll(false);
                } else if (containerRef.current.scrollTop + 20 < assectCardHeight) {
                    containerRef.current.scrollTop += 20;
                    stopAnimation = requestAnimationFrame(step);
                } else if (containerRef.current.scrollTop < assectCardHeight) {
                    containerRef.current.scrollTop = assectCardHeight;
                    setScroll(false);
                } else {
                    setScroll(false);
                }
            };
            step();
        }
        return () => stopAnimation && cancelAnimationFrame(stopAnimation);
    }, [scroll]);
    useEffect(() => {
        // 在iphone上浏览器scroll和动画冲突，导致页面抖动，所以在播放滚动动画时禁用滚动条
        if (containerRef.current && scroll) {
            containerRef.current.style.overflow = 'hidden';
        }
        return () => {
            if (containerRef.current && scroll) {
                containerRef.current.style.overflow = 'auto';
                containerRef.current.style.overflowX = 'hidden';
            }
        };
    }, [scroll]);
    const onScroll = () => {
        if (netAssetCardRef.current) {
            const assectCardHeight = getHeight(netAssetCardRef.current);
            const afterScrollTop = containerRef.current.scrollTop;
            if (
                afterScrollTop - beforeScrollTop.current > 2 &&
                afterScrollTop > 0 &&
                afterScrollTop < assectCardHeight
            ) {
                setScroll(true);
            } else {
                setScroll(false);
            }
            beforeScrollTop.current = afterScrollTop;
        }
    };

    const getComponent = () => {
        let name = secCates?.[currentIndex]?.name;
        let mapper = {
            '股权': hasToken && <Shares_card secCates={secCates}/>,
            '现金': <Cash_card secCates={secCates}/>,
        };
        return mapper[name];
    };

    return (
        <>
            {!!isReady && (
                <BasicWrapper needTabBar={true}>
                    {/* <Header needBackToHome={false} needBack={false} title="资产"></Header> */}
                    <div className={styles.container} onScroll={onScroll} ref={containerRef}>
                        <NetAssetCard ref={netAssetCardRef} />
                        <div className={styles.tab__wrap}>
                            {secCates.map((secCate, index) => {
                                return (
                                    <div
                                        key={secCate.sec_cate_id}
                                        onClick={() => changeTab(index, secCate.name)}
                                        className={currentIndex === index ? styles.active : ''}
                                    >
                                        {secCate.name}
                                    </div>
                                );
                            })}
                        </div>
                        <div className={styles.ball__wrap}>
                            {!hasToken && (
                                <div
                                    className={styles['shadow-layer']}
                                    onClick={onClickShadowLayer}
                                >
                                    <div className={styles['login__btn']}>登录查看资产</div>
                                </div>
                            )}
                            <div className={styles.ball__bgp}>
                                <div className={styles.ball__gbp__little}>
                                    <img src={Logo} alt="" />
                                </div>
                            </div>
                            {secCates.map((secCate, index) => {
                                return (
                                    <div
                                        key={secCate.sec_cate_id}
                                        onClick={() => changeTab(index, secCate.name)}
                                        className={`${styles.ball} ${
                                            styles['ball__inactive__' + index]
                                        } ${
                                            currentIndex === index
                                                ? styles.ball__active
                                                : styles.ball__inactive
                                        }`}
                                    >
                                        <div
                                            className={`${
                                                currentIndex === index
                                                    ? styles.ball__active__name
                                                    : styles.ball__inactive__name
                                            }`}
                                        >
                                            {secCate.name}
                                        </div>
                                        <div
                                            className={`${
                                                currentIndex === index
                                                    ? styles.ball__active__per
                                                    : styles.ball__inactive__per
                                            }`}
                                        >
                                            {secCate.percentage}%
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={styles.line_wrap}>
                            <div
                                className={`${styles.line} ${
                                    styles['line_position_' + currentIndex]
                                }`}
                            ></div>
                        </div>
                        {/* 根据 index 来拿到不同的组件 */}
                        <div>
                            {
                                // secCates?.[currentIndex]?.name === '股权' && <Shares_card />
                                getComponent()
                            }
                        </div>
                        <div className={styles.list__wrap}>
                            {secCates?.[currentIndex]?.desc && (
                                <div className={styles.list__head}>
                                    {secCates?.[currentIndex]?.desc}
                                </div>
                            )}
                            <div className={styles.list_container}>
                                <div className={styles.list__content}>
                                    {group(secCates?.[currentIndex]?.sub_sec_cates, 3).map(
                                        sec_cates => (
                                            <div
                                                key={sec_cates[0]?.sub_sec_cate_id}
                                                className={styles.list_row}
                                            >
                                                {sec_cates.map(sec_cate => (
                                                    <div
                                                        className={styles.list__item}
                                                        key={sec_cate.sub_sec_cate_id}
                                                        onClick={() =>
                                                            onClickSecCate(sec_cate.sub_sec_cate_id)
                                                        }
                                                    >
                                                        <div className={styles.list_icon}>
                                                            <img src={Logo} alt="" />
                                                        </div>
                                                        <div className={styles.list_text}>
                                                            {sec_cate.name}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ),
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.tips}>以上信息不构成投资建议</div>
                        <FeedbackBtn style={{ margin: '0.213333333rem 0 0.266666667rem 0' }} />
                        <div className={styles['bottom-space']}></div>
                    </div>
                </BasicWrapper>
            )}
        </>
    );
};
