import './index.scss';
import qrcodeOfficial from '../../../../images/qr_code_wechat_official.png';
import emailIcon from '../../../../images/email.png';
import qrcode from '../../../../images/qrcode.png';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const QRCode = props => (
    <div className="lk-contack-qr-code">
        {/* <img src={props.imgSrc} alt="QR Code" /> */}
        <img src={props.imgUrl} alt="" />
        <div className="lk-contack-title">{props.title}</div>
        <div className="lk-contack-subtitle">{props.subtitle}</div>
    </div>
);
const Contact = () => {
    const { t, i18n } = useTranslation();
    const is1024 = useSelector(state => state.windowSizeReducer.mobileType === 1024);

    return (
        <>
            <div
                className={`lk-contack-info ${is1024 ? 'lk-contack-info-1024' : ''} ${
                    is1024 && i18n.language === 'en' ? 'lk-contack-info-1024-en' : ''
                }`}
            >
                <div className="lk-contack-qr-codes">
                    <QRCode
                        imgUrl={qrcodeOfficial}
                        title={t('official-code-title')}
                        subtitle={t('official-code-desc')}
                    />
                    <QRCode
                        imgUrl={qrcode}
                        title={t('client-code-title')}
                        subtitle={t('client-code-desc')}
                    />
                </div>
                <div className="lk-contack-text-info">
                    <div>
                        <div className="lk-contack-text-info-sub1">{t('address-sg')}</div>
                        <div className="lk-contack-text-info-sub2">{t('address-sg-detail')}</div>
                    </div>

                    <div>
                        <div className="lk-contack-text-info-sub1">{t('address-china')}</div>
                        <div className="lk-contack-text-info-sub2">{t('address-detail')}</div>
                    </div>
                    <div>
                        <div className="lk-contack-text-info-sub1">{t('address-hongkong')}</div>
                        <div className="lk-contack-text-info-sub2">
                            {t('address-hongkong-detail')}
                        </div>
                    </div>
                    {/* <div className="lk-contack-text-info-sub5">Email:hello@ilookcap.com</div> */}
                    <a
                        className="lk-contack-text-info-sub5"
                        href="mailto:hello@ilookcap.com?subject=test&subject=&body="
                    >
                        <img src={emailIcon} alt="" className="lk-contact-email"></img>
                        Email:hello@ilookcap.com
                    </a>
                </div>
            </div>
        </>
    );
};
export default Contact;
