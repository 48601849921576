import { useLocation, useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import styles from './header.module.scss';
import HOME_ICON from '../../assets/images/home.png';
import track from '../../utils/track';
import Back_Icon from '../../assets/images/backIcon.png';

/**
 * 参数
 * needBack boolean default: true
 * title string default: ''
 */
const Header = ({
    title = '',
    needBack = false,
    onClickBack,
    headerBgColor = '#1F1F1F',
    needBackToHome = true,
}) => {
    const history = useHistory();
    const location = useLocation();

    !onClickBack &&
        (onClickBack = () => {
            /** history stack === 1 */
            if (history.length === 1) {
                history.length === 1 && history.replace('/property');
            } else {
                history.goBack();
            }
        });

    /** 返回首页 */
    const onClickBackToHome = () => {
        if (location.pathname === '/property') {
            return;
        } else {
            track.send({
                tp: '2',
                dc: '10001',
                message: '返回首页',
            });
            history.replace('/property');
        }
    };

    return (
        <div className={styles.header} style={{ backgroundColor: headerBgColor }}>
            {!!needBack && (
                <div className={styles['back-wrap']} onClick={onClickBack}>
                    {/* <LeftOutlined
                        style={{ fontSize: '0.444rem' }}
                        className={styles['back-icon']}
                    /> */}
                    <img
                        style={{ fontSize: '0.444rem' }}
                        className={styles['back-icon']}
                        src={Back_Icon}
                    />
                </div>
            )}

            <div className={styles.title}>{title}</div>

            {!!needBackToHome && (
                <div className={styles.home} onClick={onClickBackToHome}>
                    <img src={HOME_ICON} className={styles.img}></img>
                </div>
            )}
        </div>
    );
};

export default connect(state => ({ lang: state.config.lang }))(Header);
