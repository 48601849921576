import s1440 from './index.module.scss';
import s1024 from './miniStyle.module.scss';
import advantageLineColor from '../../../../images/advantage-line-color.png';
import tecImg1 from '../../../../images/tecImg1.png';
import tecImg2 from '../../../../images/tecImg2.png';
import tecImg0 from '../../../../images/tecImg0.png';
import tecImg1en from '../../../../images/tecImg2en.png';
import tecImg2en from '../../../../images/tecImg3en.png';
import tecImg0en from '../../../../images/tecImg1en.png';
import service1 from '../../../../images/service1.png';
import service2 from '../../../../images/service2.png';
import service3 from '../../../../images/service3.png';
import fzpng from '../../../../images/black_fz.png';
import investment1 from '../../../../images/investment1.png';
import investment2 from '../../../../images/investment2.png';
import { useTranslation } from 'react-i18next';
import { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import misc from '../../../../services/misc';
const Support = () => {
    const [tecIndex, setTecIndex] = useState(0);
    const is1024 = useSelector(state => state.windowSizeReducer.mobileType === 1024);
    const s = useMemo(() => {
        return is1024 ? s1024 : s1440;
    }, [is1024]);
    const { t, i18n } = useTranslation();
    const getImg = useCallback(() => {
        let en = [tecImg0en, tecImg1en, tecImg2en];
        let ch = [tecImg0, tecImg1, tecImg2];
        if (i18n.language === 'en') {
            return en[tecIndex];
        } else {
            return ch[tecIndex];
        }
    }, [i18n, tecIndex]);

    const buttonArray = useMemo(
        () => [
            t('support-technology-btn1'),
            t('support-technology-btn2'),
            t('support-technology-btn3'),
        ],
        [t],
    );
    const serviceArray = useMemo(
        () => [
            {
                icon: service1,
                title: t('support-sevices-item1-title'),
                desc: [t('support-sevices-item1-des1'), t('support-sevices-item1-des2')],
            },
            {
                icon: service2,
                title: t('support-sevices-item2-title'),
                desc: [
                    t('support-sevices-item2-des1'),
                    t('support-sevices-item2-des2'),
                    t('support-sevices-item2-des3'),
                    t('support-sevices-item2-des4'),
                    t('support-sevices-item2-des5'),
                ],
            },
            {
                icon: service3,
                title: t('support-sevices-item3-title'),
                desc: [t('support-sevices-item3-des2'), t('support-sevices-item3-des3')],
            },
        ],
        [t],
    );

    const investmentArray = useMemo(
        () => [
            {
                title: t('support-investment-array-title1'),
                desc: t('support-investment-array-desc1'),
            },
            {
                title: t('support-investment-array-title2'),
                desc: t('support-investment-array-desc2'),
            },
            {
                title: t('support-investment-array-title3'),
                desc: t('support-investment-array-desc3'),
            },
            {
                title: t('support-investment-array-title4'),
                desc: t('support-investment-array-desc4'),
            },
        ],
        [t],
    );
    return (
        <>
            <div className={s.content} id="business">
                <div className={s.stickTop} style={{ pointerEvents: 'none' }}>
                    <div className={s.stickbg}>
                        <div className={s.containerImg}>
                            <img src={advantageLineColor} alt="" className={s.lkAdvantageLine} />
                        </div>
                        <div className={s.title}>{t('support-title')}</div>
                        <div className={s.topTitle}>{t('support-subtitle')}</div>
                    </div>
                    {/* <img src={topIcon} alt="" className={s.topIcon} />
                    <img
                        src={downIcon}
                        alt=""
                        className={s.downIcon}
                        style={{ opacity: !inBody ? '0' : '1' }}
                    /> */}
                </div>

                <div className={`${s.container} ${s.container1}`}>
                    <div className={s.title}>{t('support-sevices-title')}</div>
                    <div className={s.desc}>{`“ ${t('support-sevices-subtitle')} ”`}</div>
                    <div
                        className={`${s.cardContent} ${i18n.language === 'en' ? s.cardContentEn : ''
                            }`}
                    >
                        {serviceArray.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`${s.cardItem} ${i18n.language === 'en' ? s.cardItemEn : ''
                                        }`}
                                >
                                    <img src={item.icon} alt="" className={s.cardIcon} />
                                    <div className={s.cardTitle}>{item.title}</div>
                                    <div className={s.cardDesc}>
                                        <ul>
                                            {item.desc.map((item, index) => (
                                                <li key={index}>{item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div
                    className={`${s.container} ${s.container2} ${i18n.language === 'en' ? s.containerEn2 : ''
                        }`}
                >
                    <div className={s.title}>{t('support-investment-title')}</div>
                    <div className={s.desc}>{`“ ${t('support-investment-des')} ”`}</div>
                    <div className={s.cardContent}>
                        <div className={s.cardLeft}>
                            <div className={s.cardLeftTop}>{t('support-investment-cio')}</div>
                            <div className={s.cardLeftBottom}>
                                <img src={fzpng} alt="" className={s.cardLeftBottomIcon} />
                                <div className={s.cardLeftBottomName}>
                                    {t('support-investment-cio-name')}
                                </div>
                                <div className={s.cardLeftBottomTitle}>
                                    {t('support-investment-cio-title')}
                                </div>
                            </div>
                        </div>
                        <div className={s.cardRight}>
                            <div className={s.cardRightTop}>
                                <div className={s.cardRightTopLeft}>
                                    <img
                                        src={investment1}
                                        alt=""
                                        className={s.cardRightTopLeftIcon}
                                    />
                                    <span>{t('support-investment-target1')}</span>
                                </div>
                                <div className={s.line}></div>
                                <div className={s.cardRightTopLeft}>
                                    <img
                                        src={investment2}
                                        alt=""
                                        className={s.cardRightTopLeftIcon}
                                    />
                                    <span>{t('support-investment-target2')}</span>
                                </div>
                            </div>
                            <div className={s.cardRightBottom}>
                                {investmentArray.map((item, index) => {
                                    return (
                                        <div className={s.cardRightBottomItem} key={index}>
                                            <div className={s.cardRightBottomItemTop}>
                                                <div className={s.block}></div>
                                                {item.title}
                                            </div>
                                            <div className={s.cardRightBottomItemDesc}>
                                                {item.desc}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`${s.container} ${s.container3} ${i18n.language === 'en' ? s.containerEn3 : ''
                        }`}
                >
                    <div className={s.title}>{t('support-technology-title')}</div>
                    <div className={s.desc}>{`“ ${t('support-technology-des')} ”`} </div>
                    <div className={s.cardContent}>
                        <img src={getImg()} alt="" />
                    </div>
                    <div className={s.button}>
                        {buttonArray.map((item, index) => {
                            return (
                                <div
                                    className={`${s.buttonItem} ${tecIndex === index ? s.selectButton : ''
                                        }`}
                                    onMouseEnter={() => {
                                        misc.eventReport([
                                            {
                                                event_type: 2,
                                                event_id: 30022,
                                                params: { title: item },
                                                ts: +new Date(),
                                                sid: '',
                                            },
                                        ]);
                                        setTecIndex(index);
                                    }}
                                    key={index}
                                >
                                    {item}
                                </div>
                            );
                        })}
                    </div>
                </div>
                {/* <div
                    ref={container2}
                    style={{
                        position: 'absolute',
                        bottom: '0',
                        left: '0',
                        height: '1px',
                        zIndex: '20',
                        width: '100vw',
                    }}
                ></div> */}
            </div>
        </>
    );
};
export default Support;
