import axios from '../utils/o-axios'

class Share {


    getWxSignature = (data) => {
        return axios.post('/v1/misc/wxjs_url_sign', {
            data,
        });
    }

}

export default new Share()


