import { createRef, useCallback, useEffect, useState, useRef } from 'react';
import styles from './portal_product_detail.module.scss';
import Header from '../../layouts/header/header';
import BasicWrapper from '../../components/BasicWrapper/BasicWrapper';
import { useHistory, useParams } from 'react-router';
import Quote from '../../services/quote';
import { showFail, showToast } from '../../utils/toast';

import CardWithName from './components/CardWithName/CardWithName';
import CardWithTable from './components/CardWithTable/CardWithTable';
import wxCode from '../../images/wxCode.png';
import { Drawer } from 'antd';
import track from '../../utils/track';
import Look_Cap from '../../assets/images/lookcap.png';
import wxJsSdk from '../../utils/wxJsSdk';
import FeedbackBtn from '../../components/FeedbackBtn/FeedbackBtn';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CardWithChart from './components/CardWithChart/CardWithChart';

export default () => {
    const history = useHistory();
    const location = useLocation();

    const params = useParams();
    const [securityInfo, setSecurityInfo] = useState({});
    const [isReady, setIsReady] = useState(false);
    const [showPop, setShowPop] = useState(false);
    useEffect(() => {
        Quote.getProductDetails({
            isin: params.sec_id,
        })
            .then(res => {
                setSecurityInfo(res.data);
                setIsReady(true);
                if (res.data === null) {
                    history.replace(`/`);
                    showToast('敬请期待');
                } else {
                    wxJsSdk.init({
                        title: res.data.asset_class_text, // 分享标题
                        desc: '鹿可财富产品详情', // 分享描述
                        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: Look_Cap, // 分享图标});
                    });
                }
            })
            .catch(err => {
                showToast(err.msg);
            });
    }, [params.sec_id]);

    const onClickShowPop = useCallback(() => {
        track.send({
            tp: '2',
            dc: '01',
            message: '进一步了解点击',
        });
        setShowPop(showPop => !showPop);
    }, []);

    const CardComponent = useCallback(({ component }) => {
        if (component.type === 'single_row') {
            return <CardWithName data={component} />;
        } else if (component.type === 'kv_list') {
            return <CardWithTable data={component} />;
        } else if (component.type === 'line_chart') {
            return <CardWithChart data={component} />;
        } else {
            return null;
        }
    }, []);

    return (
        <>
            {isReady && (
                <BasicWrapper>
                    <div className={styles.content}>
                        {/* 渲染详情页里的内容 */}
                        {securityInfo.sections?.map((component, index) => {
                            return (
                                <div className={styles['card-item']} key={index}>
                                    <CardComponent component={component} />
                                </div>
                            );
                        })}

                        <div className={styles.btn__wrap}>
                            <div
                                className={`${styles.drawer} ${
                                    showPop ? styles['show-drawer'] : ''
                                }`}
                            >
                                <div className={styles['drawer-title']}>
                                    <div>扫描二维码，</div>
                                    <div>添加鹿可财富官方微信</div>
                                </div>
                                <img src={wxCode} alt="" className={styles.code__img} />
                                <div className={styles.pop__phone}>
                                    <span className={styles['phone-title']}>联系电话</span>
                                    <a href="tel:15718873475">15718873475</a>
                                </div>
                            </div>
                            <div className={styles['btn-container']}>
                                <button className={styles.btn} onClick={onClickShowPop}>
                                    {showPop ? '关闭' : '进一步了解'}
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* 进一步了解的底部弹窗 */}
                </BasicWrapper>
            )}
        </>
    );
};
